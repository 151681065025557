import { LoginResponse } from "@bringg/react-components/dist/components/login/login";
import { Merchant, MerchantData } from "@bringg/types";
import get from "lodash/get";
import { computed, observable } from "mobx";
import { addRoot, getEnv, setter } from "mobx-easy";

import { RootEnv } from "../../create-store";
import { RootStore } from "../../root-store";

interface AuthStore {
  setLogin: (flag: boolean) => void;
  setFirstLogin: (flag: boolean) => void;
  getRoot: () => RootStore;
}

@addRoot
class AuthStore {
  @observable
  @setter("setLogin")
  isLoggedIn: boolean = false;

  @observable
  @setter("setFirstLogin")
  firstLogin = true;

  _merchant: Merchant = null;

  @computed
  public get recaptchaSiteKey() {
    return getEnv<RootEnv>().envConfig.recaptcha_site_key;
  }

  @computed
  public get mapboxAccessToken() {
    return getEnv<RootEnv>().envConfig.mapbox_access_token;
  }

  @computed
  public get apiEndpoint() {
    const { dashboardSDK, envConfig } = getEnv<RootEnv>();

    return envConfig.regions[dashboardSDK.sdk.session.region].api_end_point;
  }

  @computed
  public get user() {
    return getEnv<RootEnv>().dashboardSDK.sdk.session.user;
  }

  @computed
  public get merchant(): Merchant {
    return this._merchant;
  }

  public async initWithAuthToken(region, token) {
    try {
      const { dashboardSDK } = getEnv<RootEnv>();

      await dashboardSDK.loginWithAuthToken(region, token);

      if (dashboardSDK.sdk) {
        await this.afterLogin();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Failed to init sdk", e);
    }
  }

  public async afterLogin() {
    const { dashboardSDK, authentication } = getEnv<RootEnv>();
    const sdk = dashboardSDK.sdk;

    this.setLogin(true);
    this._merchant = await dashboardSDK.sdk.merchant.get();

    await this.initFirstLogin();

    authentication.storeCredentials(
      sdk.session.user.authentication_token,
      sdk.session.region,
      sdk.session.user.language
    );

    this.getRoot().dataStore.afterLogin();
    this.getRoot().viewStore.afterLogin();
  }

  public async initSdkFromLocalEnvironment() {
    try {
      const { dashboardSDK } = getEnv<RootEnv>();

      await dashboardSDK.init();

      if (dashboardSDK.sdk) {
        await this.afterLogin();
        // remove query params
        window.location.hash = window.location.hash.split("?")[0];
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Failed to init sdk", e);
    }
  }

  logout = () => {
    this.setLogin(false);
    const { dashboardSDK, authentication } = getEnv<RootEnv>();

    this.getRoot().dataStore.afterLogout();
    authentication.clearCredentials();
    dashboardSDK.destroy();
  };

  attemptToLogin = async (
    email: string,
    password: string,
    selectedMerchant?: MerchantData,
    captcha?: string
  ): Promise<LoginResponse> => {
    const result = await getEnv<RootEnv>().authentication.attemptToLogin(
      email,
      password,
      selectedMerchant,
      captcha
    );

    if (result.success) {
      await this.afterLogin();
    }

    return result;
  };

  getMerchantTableConfiguration = async () => {
    const { dashboardSDK } = getEnv<RootEnv>();
    const merchantConfiguration =
      await dashboardSDK.sdk.merchantConfiguration.get();
    const tableConfiguration = get(
      merchantConfiguration,
      "dashboard_ui_configuration.table_configuration"
    );

    return tableConfiguration || {}; // In case if null
  };

  initFirstLogin = async () => {
    const isFirstLogin = !get(
      await this.getMerchantTableConfiguration(),
      "portalWelcomeAcknowledge"
    );

    this.setFirstLogin(isFirstLogin);
  };

  changeFirstLogin = async (value: boolean) => {
    this.setFirstLogin(value);

    const { dashboardSDK } = getEnv<RootEnv>();

    await dashboardSDK.sdk.merchantConfiguration.dashboardTableConfigurationUpdate(
      {
        ...(await this.getMerchantTableConfiguration()),
        portalWelcomeAcknowledge: !value,
      }
    );
  };
}

export { AuthStore };
