// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Table } from "antd";
import { TFunction } from "i18next";
import { findKey } from "lodash";
import { useObserver } from "mobx-react";

import { SectionBlock } from "../../../component/section-block/section-block";
import UrlCopier from "../../../component/url-copier/url-copier";
import { API_HOSTS } from "../../../consts";
import { useStore } from "../../../stores/use-store";

import "./registered-merchants.scss";

const getCell = (text: string): JSX.Element => (
  <div className="registered-merchants-cell">
    <span>{text}</span>
    <UrlCopier transparent={true}>{text}</UrlCopier>
  </div>
);

const getEndpoint = (text: string): string =>
  findKey(API_HOSTS, (value) => value === text);

const getColumns = (t: TFunction): object[] => {
  return [
    {
      title: t("MERCHANT_NAME_COLUMN"),
      dataIndex: "merchant_name",
    },
    {
      title: t("CLIENT_ID_COLUMN"),
      dataIndex: ["credentials", "client_id"],
      render: getCell,
    },
    {
      title: t("CLIENT_SECRET_COLUMN"),
      dataIndex: ["credentials", "client_secret"],
      render: getCell,
    },
    {
      title: t("API_KEY_COLUMN"),
      dataIndex: ["credentials", "api_key"],
      render: getCell,
    },
    {
      title: t("END_POINT_PREFIX"),
      dataIndex: "api_endpoint",
      render: getCell,
    },
    {
      title: t("ENVIRONMENT"),
      dataIndex: "api_endpoint",
      render: getEndpoint,
      width: "10%",
    },
  ];
};

const RegisteredMerchants = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    dataStore: {
      registeredMerchantsStore,
      adminCredentialsStore,
      fleetSettingsStore,
    },
  } = useStore();

  useEffect(() => {
    registeredMerchantsStore.fetch();
    fleetSettingsStore.fetch();
    adminCredentialsStore.fetch();
  }, [adminCredentialsStore, fleetSettingsStore, registeredMerchantsStore]);

  return useObserver(() => (
    <div className="registered-merchants">
      <h1>Registered merchants</h1>
      <p>
        Below find the credentials of shippers who have added you as a delivery
        provider. Also find the credentials for your Sandbox Merchant (test
        shipper) account.
      </p>

      {registeredMerchantsStore.isFetched && (
        <Table
          columns={getColumns(t)}
          dataSource={registeredMerchantsStore.registeredMerchants}
          pagination={false}
          tableLayout="fixed"
        />
      )}

      {fleetSettingsStore.isFetched && (
        <SectionBlock
          title="Merchant credentials"
          description="Use this endpoint URL to send an automatic request for shipper credentials once they add you as a delivery provider."
        >
          <UrlCopier title="Get merchant credentials">
            {adminCredentialsStore.getEndpoint}
          </UrlCopier>
        </SectionBlock>
      )}

      {adminCredentialsStore.isFetched && (
        <SectionBlock title="Admin credentials">
          <Row justify="start" gutter={[52, 0]}>
            <Col>
              <UrlCopier title="Client ID" type="KEY">
                {adminCredentialsStore.clientId}
              </UrlCopier>
            </Col>

            <Col>
              <UrlCopier title="Client Secret" type="KEY">
                {adminCredentialsStore.secret}
              </UrlCopier>
            </Col>
          </Row>
        </SectionBlock>
      )}
    </div>
  ));
};

export { RegisteredMerchants };
