// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "@bringg/react-components/dist/components/modal/modal";
import {
  Button,
  Form,
  FormInstance,
  Input,
  Radio,
  RadioChangeEvent,
  Table,
} from "antd";

import JsonEditor from "./json-editor";
import {
  transformFromTaskToTable,
  transformTableToTask,
} from "./transformData";

interface DataType {
  key: React.Key;
  send: boolean;
  fieldType: string;
  value: any;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof DataType;
  record: DataType;
  handleSave: (record: DataType) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const AutomaticMapper = ({ taskRows, setTaskRows }) => {
  const defaultColumns = [
    {
      title: "Field type",
      dataIndex: "fieldType",
      key: "fieldType",
      editable: true,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      editable: true,
      render: (text) => String(text),
    },
  ];

  const handleSave = (row: DataType) => {
    const newData = [...taskRows];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setTaskRows(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <Table
      components={components}
      rowSelection={{
        type: "checkbox",
        columnTitle: "Send",
        selections: true,
        selectedRowKeys: taskRows
          .filter((item) => item.send)
          .map((item) => item.key),
        onSelect: (record, selected) => {
          const index = taskRows.findIndex((value) => value.key === record.key);

          taskRows[index].send = selected;
          setTaskRows([...taskRows]);
        },
      }}
      dataSource={taskRows}
      columns={columns}
      pagination={false}
      scroll={{ y: "45vh" }}
    />
  );
};

const ManualMapper = ({ task, setTask }) => {
  return (
    <div className="json-input-container">
      <Button onClick={() => setTask({})}>Clear</Button>
      <JsonEditor
        placeholder={task}
        onChange={(value) => {
          if (!value.error) {
            setTask(value.jsObject);
          }
        }}
      />
    </div>
  );
};

export const TaskMapper = ({
  loading = false,
  onOk,
  onCancel,
  isModalOpen,
  taskProp,
  title,
  subTitle,
}) => {
  const [creatingTaskFormat, setCreatingTaskFormat] = useState<
    "automatic" | "manually"
  >("automatic");
  const [task, setTask] = useState<any>();
  const [taskRows, setTaskRows] = useState<any>();

  useEffect(() => {
    if (isModalOpen) {
      setCreatingTaskFormat("automatic");
      setTaskRows(transformFromTaskToTable(taskProp));
      setTask(taskProp);
    }
  }, [taskProp, isModalOpen]);

  const changeCreatingTaskFormat = (e: RadioChangeEvent) => {
    setCreatingTaskFormat(e.target.value);
  };

  return (
    <>
      <Modal
        // eslint-disable-next-line
        // @ts-ignore this lib is incompatible with react18
        visible={isModalOpen}
        confirmLoading={loading}
        width={1037}
        className="task-mapper"
        okText="Done"
        onOk={async () => {
          await onOk(
            creatingTaskFormat === "automatic"
              ? transformTableToTask(taskRows)
              : task
          );
        }}
        onCancel={onCancel}
      >
        <h1>Testing Before Publishing</h1>
        <p>
          Verify that your payload contains all the data you need. You can
          either use all of the fields in the default payload displayed below,
          or select which fields you wish to send. Alternatively, you can
          proceed to the next screen to add a custom payload in JSON format.
        </p>
        <h1>{title}</h1>
        <div className="radio-container">
          <p>{subTitle}</p>
          <Radio.Group
            value={creatingTaskFormat}
            onChange={changeCreatingTaskFormat}
          >
            <Radio value="automatic">automatic</Radio>
            <Radio value="manually">manually</Radio>
          </Radio.Group>
        </div>
        {creatingTaskFormat === "automatic" ? (
          <AutomaticMapper taskRows={taskRows} setTaskRows={setTaskRows} />
        ) : (
          <ManualMapper task={task} setTask={setTask} />
        )}
      </Modal>
    </>
  );
};
