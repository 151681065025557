// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TableWrapper from "@bringg/react-components/dist/components/table/table";
import { Task, TaskStatus } from "@bringg/types";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Menu, notification } from "antd";
import { TFunction } from "i18next";
import _get from "lodash/get";
import _pick from "lodash/pick";
import { useObserver } from "mobx-react";

import { FleetSelect } from "../../../component/fleet-select/fleet-select";
import { StatusProgress } from "../../../component/status-progress/status-progress";
import { TimeWindow } from "../../../component/time-window/time-window";
import { useHasFeatureFlag } from "../../../hooks/use-has-feature-flag";
import { useStore } from "../../../stores/use-store";
import { TaskMapper } from "../task-mapper/task-mapper";
import updateFields from "../task-mapper/updateFields.json";

const getColumns = (t: TFunction, newVersion: boolean, onUpdate, onCancel) => {
  const columns = [
    {
      title: t("DISPATCH_ID_COLUMN"),
      render: (text: string, task: Bringg.Task) => (
        <Link to={`order/${task.id}`}>{task.id}</Link>
      ),
      dataIndex: "id",
      width: "8%",
      sorter: (a, b) => sorter(a, b, "id"),
      defaultSortOrder: "descend" as any,
    },
    {
      title: t("DISPATCH_EXTERNAL_ID_COLUMN"),
      dataIndex: "external_id",
      width: "10%",
      sorter: (a, b) => sorter(a, b, "external_id"),
    },
    {
      title: t("DISPATCH_FLEET_COLUMN"),
      width: "10%",
      render: (text: string, task: Bringg.Task) => (
        <FleetSelect taskId={task.id} fleetId={task.fleet_id} />
      ),
      sorter: (a, b) => sorter(a, b, "fleet_id"),
      dataIndex: "fleet_id",
    },
    {
      title: t("DISPATCH_DRIVER_COLUMN"),
      width: "10%",
      dataIndex: "userName",
      sorter: (a, b) => sorter(a, b, "userName"),
    },
    {
      title: t("DISPATCH_PROGRESS_COLUMN"),
      width: newVersion ? "12%" : "15%",
      render: (text: string, task: Bringg.Task) => (
        <StatusProgress task={task} />
      ),
    },
    {
      title: t("DISPATCH_STATUS_COLUMN"),
      dataIndex: "status",
      width: "7%",
      render: (text: string, task: Bringg.Task) =>
        t(TaskStatus[task.status || 0]),
      sorter: (a, b) => sorter(a, b, "status"),
    },
    {
      title: t("DISPATCH_FIRST_ADDRESS_COLUMN"),
      width: "10%",
      dataIndex: "way_points[0].address",
      sorter: (a, b) => sorter(a, b, "way_points[0].address"),
    },
    {
      title: t("DISPATCH_SECOND_ADDRESS_COLUMN"),
      width: "10%",
      dataIndex: "way_points[1].address",
      sorter: (a, b) => sorter(a, b, "way_points[1].address"),
    },
    {
      title: t("DISPATCH_FIRST_TIME_WINDOW_COLUMN"),
      width: "10%",
      render: (text: string, task: Bringg.Task) => (
        <TimeWindow wayPoint={_get(task, "way_points[0]")} />
      ),
      sorter: (a, b) => sorter(a, b, "way_points[0].no_earlier_than"),
      dataIndex: "way_points[0].no_earlier_than",
    },
    {
      title: t("DISPATCH_SECOND_TIME_WINDOW_COLUMN"),
      width: "10%",
      render: (text: string, task: Bringg.Task) => (
        <TimeWindow wayPoint={_get(task, "way_points[1]")} />
      ),
      sorter: (a, b) => sorter(a, b, "way_points[1].no_earlier_than"),
      dataIndex: "way_points[1].no_earlier_than",
    },
  ];

  if (newVersion) {
    columns.push(
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      {
        width: "3%",
        render: (text, task: Task) => (
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <Menu.Item onClick={(): void => onUpdate(task)}>
                  Update
                </Menu.Item>
                <Menu.Item onClick={(): void => onCancel(task)}>
                  Cancel
                </Menu.Item>
              </Menu>
            }
          >
            <Button type="text">
              <FontAwesomeIcon icon={faEllipsisV} />
            </Button>
          </Dropdown>
        ),
      }
    );
  }

  return columns;
};

export const sorter = (
  task1: Bringg.Task,
  task2: Bringg.Task,
  path: string
): number => {
  const firstValue = _get(task1, path);
  const secondValue = _get(task2, path);

  if (!secondValue) {
    return 1;
  }

  if (!firstValue) {
    return -1;
  }

  return firstValue < secondValue ? -1 : 1;
};

interface Props {
  loading: boolean;
}

export const DispatchTable: FunctionComponent<Props> = ({ loading }) => {
  const {
    dataStore: { tasksStore },
  } = useStore();
  const newVersion = useHasFeatureFlag("enable_partner_portal");
  const { t } = useTranslation();

  const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false);
  const [activeTaskId, setActiveTaskId] = useState<number>(null);
  const [taskProp, setTaskProp] = useState<Partial<Bringg.Task>>(null);

  const onOkEditor = async (diff): Promise<void> => {
    try {
      setIsEditorOpen(false);

      // eslint-disable-next-line @typescript-eslint/camelcase
      await tasksStore.update(activeTaskId, { ...diff, task_id: activeTaskId });

      notification.success({
        message: t("UPDATE_ORDER_SUCCESS"),
        placement: "bottomRight",
      });
    } catch (error) {
      notification.error({
        message: t("UPDATE_ORDER_ERROR"),
        placement: "bottomRight",
      });
    }
  };

  const onCloseEditor = (): void => {
    setIsEditorOpen(false);
  };

  const onUpdate = (task: Task): void => {
    if (activeTaskId !== task.id) {
      setActiveTaskId(task.id);
      setTaskProp(_pick(task, updateFields));
    }

    setIsEditorOpen(true);
  };

  const onCancel = async (task: Task): Promise<void> => {
    try {
      await tasksStore.cancel(task.id);

      notification.success({
        message: t("CANCEL_ORDER_SUCCESS"),
        placement: "bottomRight",
      });
    } catch (error) {
      notification.error({
        message: t("CANCEL_ORDER_ERROR"),
        placement: "bottomRight",
      });
    }
  };

  return useObserver(() => (
    <span className="dispatch-table">
      <TableWrapper
        useFixedHeader={true}
        className="table-wrapper"
        collection={tasksStore.tasks}
        columns={getColumns(t, newVersion, onUpdate, onCancel)}
        loading={loading}
        rowKey="id"
      />

      <TaskMapper
        title="Delivery Updated API"
        subTitle="Paste your own order update payload in JSON format below."
        isModalOpen={isEditorOpen}
        taskProp={taskProp}
        onCancel={onCloseEditor}
        onOk={onOkEditor}
      />
    </span>
  ));
};
