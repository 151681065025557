// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect } from "react";
import { useObserver } from "mobx-react";

import { SectionBlock } from "../../../component/section-block/section-block";
import UrlCopier from "../../../component/url-copier/url-copier";
import { useStore } from "../../../stores/use-store";

import "./sandbox-api-key.scss";

const SandboxApiKey = () => {
  const {
    dataStore: { fleetSettingsStore },
  } = useStore();

  useEffect(() => {
    fleetSettingsStore.fetch();
  }, [fleetSettingsStore]);

  return useObserver(() => {
    return (
      <div className="sandbox-api-key">
        <SectionBlock
          title="Sandbox API Key"
          explanation="This API key will be sent to your API upon test order creation from this portal, use it to differentiate between your testing activities and real orders"
        >
          <UrlCopier type="KEY" title="Sandbox API Key">
            {fleetSettingsStore.fleetSettings?.key}
          </UrlCopier>
        </SectionBlock>
      </div>
    );
  });
};

export { SandboxApiKey };
