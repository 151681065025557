// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Pages, {
  RouteDetails,
} from "@bringg/react-pages/dist/components/pages/pages";
import { Col, Menu, Row } from "antd";
import { TFunction } from "i18next";
import { get } from "lodash";
import { useObserver } from "mobx-react";

import { LearnMoreButton } from "../../component/learn-more-button/learn-more-button";
import { useHasFeatureFlag } from "../../hooks/use-has-feature-flag";
import { useStore } from "../../stores/use-store";
import { ApisList } from "./apis-list/apis-list";
import { MerchantsList } from "./merchants-list/merchants-list";
import { RegisteredMerchants } from "./registered-merchants/registered-merchants";
import { WebhooksApisSettings } from "./webhooks-settings/webhook-settings-container";

const getRegistrationRoutes = (t: TFunction): RouteDetails[] => [
  {
    path: "webhooks",
    title: t("WEBHOOKS_SETTING_TITLE"),
    component: WebhooksApisSettings,
  },
  {
    path: "api",
    title: t("API_SETTING_TITLE"),
    component: ApisList,
    default: true,
  },
  {
    path: "merchants",
    title: t("MERCHANTS_LIST_TITLE"),
    component: MerchantsList,
    default: true,
  },
];

export const Registration: FunctionComponent = () => {
  const routeMatch = useRouteMatch("/registration/:endpoint");
  const newVersion = useHasFeatureFlag("enable_partner_portal");
  const { t } = useTranslation();
  const {
    dataStore: { fleetSettingsStore },
  } = useStore();

  useEffect(() => {
    const init = async () => {
      await fleetSettingsStore.fetch();
    };

    init();
  }, [fleetSettingsStore]);

  return useObserver(() =>
    newVersion ? (
      <>
        <Row className="page-navigation" justify="space-between" align="middle">
          <Col flex="auto">
            <Menu selectedKeys={[get(routeMatch, "params.endpoint")]}>
              <Menu.Item key="webhooks">
                <Link to="/registration/webhooks">
                  {t("WEBHOOKS_SETTING_TITLE")}
                </Link>
              </Menu.Item>
              <Menu.Item key="api">
                <Link to="/registration/api">{t("API_SETTING_TITLE")}</Link>
              </Menu.Item>
              <Menu.Item key="merchants">
                <Link to="/registration/merchants">
                  {t("MERCHANTS_LIST_TITLE")}
                </Link>
              </Menu.Item>
            </Menu>
          </Col>
          <Col flex="none">
            <LearnMoreButton />
          </Col>
        </Row>

        <Switch>
          <Route path="/registration/webhooks">
            <WebhooksApisSettings />
          </Route>
          <Route path="/registration/api">
            <ApisList />
          </Route>
          <Route path="/registration/merchants">
            {newVersion ? <RegisteredMerchants /> : <MerchantsList />}
          </Route>

          <Redirect to="/registration/webhooks" />
        </Switch>
      </>
    ) : (
      <div>
        <span className="fleet-name">{fleetSettingsStore.name}</span>
        <Pages routes={getRegistrationRoutes(t)} />
      </div>
    )
  );
};
