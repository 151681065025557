// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react";

import { QuestionMark } from "../question-mark/question-mark";

import "./section-block.scss";

interface SectionBlockProps {
  title: string;
  description?: string;
  explanation?: string;
  children: JSX.Element;
}

const SectionBlock = ({
  title,
  explanation,
  description,
  children,
}: SectionBlockProps) => (
  <div className="section-block">
    <h3 className="section-block-title">
      {title}
      {explanation ? <QuestionMark>{explanation}</QuestionMark> : null}
    </h3>
    <p className="section-block-description">{description}</p>
    <div className="section-block-content">{children}</div>
  </div>
);

export { SectionBlock };
