import { Driver as BringgDriver } from "@bringg/types";
import { action, computed, observable } from "mobx";
import { addRoot, getEnv } from "mobx-easy";

import { RootEnv } from "../../create-store";
import { RootStore } from "../../root-store";
import { Driver } from "./driver";

export interface DriversStore {
  getRoot: () => RootStore;
}

@addRoot
export class DriversStore {
  @observable.shallow driverById: Map<number, Driver> = new Map();

  @action
  private setDrivers(drivers: BringgDriver[]) {
    drivers.forEach((driver) => this.setDriver(driver));
  }

  setDriver = (driver: BringgDriver) => {
    this.driverById.set(driver.id, new Driver(driver));

    const {
      viewStore: { mapViewStore },
    } = this.getRoot();

    mapViewStore.setDriverMarker(driver);
  };

  @computed
  public get drivers(): Driver[] {
    return Array.from(this.driverById.values());
  }

  public async fetch() {
    const { dashboardSDK } = getEnv<RootEnv>();
    const response = await dashboardSDK.sdk.users.getAllDrivers({ limit: 50 });
    const drivers: BringgDriver[] = response.users;

    await this.setDrivers(drivers);
  }

  @action
  public async getById(driverId: number): Promise<Driver> {
    if (this.driverById.has(driverId)) {
      return this.driverById.get(driverId);
    }

    const { dashboardSDK } = getEnv<RootEnv>();
    const driver: BringgDriver = await dashboardSDK.sdk.users.getDriver(
      driverId
    );

    this.setDriver(driver);

    return driver;
  }

  onDriverDeleted = (driver: BringgDriver) => {
    this.driverById.delete(driver.id);

    const {
      viewStore: { mapViewStore },
    } = this.getRoot();

    mapViewStore.deleteDriverMarker(driver);
  };

  onDriverCreatOrUpdate = (driver: BringgDriver) => {
    this.setDriver(driver);
  };

  afterLogin = () => {
    const { dashboardSDK } = getEnv<RootEnv>();

    dashboardSDK.sdk.users.onCreate(this.onDriverCreatOrUpdate);
    dashboardSDK.sdk.users.onUpdate(this.onDriverCreatOrUpdate);
    dashboardSDK.sdk.users.onDelete(this.onDriverDeleted);
  };

  public afterLogout() {
    const { dashboardSDK } = getEnv<RootEnv>();

    dashboardSDK.sdk.users.unsubscribe();
  }
}
