// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react";
import ReactDOM from "react-dom";
import { ConfigLoader } from "@bringg/frontend-utils/";
import { Environment } from "@bringg/frontend-utils/dist/common/types";
import Authentication from "@bringg/frontend-utils/dist/services/authentication";
import { StorageType } from "@bringg/frontend-utils/dist/services/credentials-storage";
import DashboardSdk from "@bringg/frontend-utils/dist/services/dashboard-sdk";

import { App } from "./App";
import { createStore } from "./stores/create-store";
import { StoreProvider } from "./stores/use-store";

import "./index.scss";
import { ErrorBoundary } from "./component/error-boundary/error-boundary";

const initApp = async () => {
  const envConfig: Environment = await new ConfigLoader().load();
  const dashboardSDK = new DashboardSdk(envConfig, "Bringg Fleet App");
  const authentication = new Authentication(
    StorageType.LocalStorage,
    dashboardSDK
  );

  const { rootStore, env } = createStore({
    envConfig,
    dashboardSDK,
    authentication,
  });

  await env.translationService.init();
  await rootStore.uiStore.authStore.initSdkFromLocalEnvironment();

  ReactDOM.render(
    <ErrorBoundary>
      <StoreProvider value={rootStore}>
        <App />
      </StoreProvider>
    </ErrorBoundary>,
    document.getElementById("root")
  );
};

initApp();
