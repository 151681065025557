// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Spinner from "@bringg/react-components/dist/components/spinner/spinner";
import { Button, Dropdown, Menu } from "antd";
import { useObserver } from "mobx-react";

import { SettingHeader } from "../../component/setting-header/setting-header";
import { useHasFeatureFlag } from "../../hooks/use-has-feature-flag";
import { useStore } from "../../stores/use-store";
import { WayPoints } from "./way-points/way-points";

type TaskMatchParam = {
  id: string;
};

type Props = RouteComponentProps<TaskMatchParam>;

export const OrderPage: FunctionComponent<Props> = (props) => {
  const {
    dataStore: { tasksStore },
  } = useStore();
  const { id: taskId } = useParams<{ id: string }>();
  const history = useHistory();
  const [task, setTask] = useState(null);
  const newVersion = useHasFeatureFlag("enable_partner_portal");

  useEffect(() => {
    const init = async () => {
      const task = await tasksStore.get(+taskId);

      setTask(task);
    };

    init();
  }, [taskId, tasksStore]);

  const onCancel = async () => {
    await tasksStore.cancel(+taskId);
    history.push("/dispatch");
  };

  return useObserver(() => (
    <div className="order-page">
      {!task ? (
        <Spinner className="spinner" />
      ) : (
        <>
          <SettingHeader
            title={
              <>
                <span>{task.title}</span>

                {newVersion ? (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={onCancel}>Cancel</Menu.Item>
                      </Menu>
                    }
                  >
                    <Button>Actions</Button>
                  </Dropdown>
                ) : null}
              </>
            }
          />
          <WayPoints task={task} />
        </>
      )}
    </div>
  ));
};
