// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import CopyToClipboard from "@bringg/react-components/dist/components/copy-to-clipboard/copy-to-clipboard";

export interface Props {
  textToCopy: string;
  buttonText: string;
}

export const CopyButton: FunctionComponent<Props> = ({
  textToCopy,
  buttonText,
}) => {
  return (
    <CopyToClipboard showText={false} text={textToCopy}>
      <button className="copy-to-clipboard-button">{buttonText}</button>
    </CopyToClipboard>
  );
};
