import { DataStore } from "./data/data-store";
import { UiStore } from "./ui/ui-store";
import { ViewStore } from "./view/view-store";

export interface RootStore {
  dataStore: DataStore;
  uiStore: UiStore;
  viewStore: ViewStore;
}

export class RootStore {
  dataStore: DataStore = {} as DataStore;
  uiStore: UiStore = {} as UiStore;
  viewStore: ViewStore = {} as ViewStore;

  public init() {
    this.dataStore = new DataStore();
    this.uiStore = new UiStore();
    this.viewStore = new ViewStore();
  }
}
