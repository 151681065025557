import { RegisteredMerchant as BringgRegisteredMerchant } from "@bringg/dashboard-sdk/dist/OpenFleet/OpenFleet.consts";

export class RegisteredMerchant {
  // eslint-disable-next-line @typescript-eslint/camelcase
  merchant_name: string;
  // eslint-disable-next-line @typescript-eslint/camelcase
  merchant_uuid: string;
  // eslint-disable-next-line @typescript-eslint/camelcase
  api_endpoint: string;

  constructor(registeredMerchant: BringgRegisteredMerchant) {
    Object.assign(this, registeredMerchant);
  }
}
