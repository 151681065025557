// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";

import "./question-mark.scss";

const QuestionMark = ({ children, ...restProps }) => {
  return (
    <Tooltip title={children} {...restProps}>
      <FontAwesomeIcon className="question-mark" icon={faQuestionCircle} />
    </Tooltip>
  );
};

export { QuestionMark };
