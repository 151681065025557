import { Task } from "@bringg/types";
import _ from "lodash";

export const transformFromTaskToTable = (task: Task) => {
  function flattenObject(ob): object {
    const toReturn = {};

    for (const i in ob) {
      if (!ob.hasOwnProperty(i)) {
        continue;
      }

      if (typeof ob[i] == "object" && ob[i] !== null) {
        const flatObject = flattenObject(ob[i]);

        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) {
            continue;
          }

          toReturn[i + "." + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }

    return toReturn;
  }

  const tableData = Object.entries(flattenObject(task)).map(
    ([fieldType, value], index) => ({
      key: index,
      send: true,
      fieldType,
      value,
    })
  );

  return _.sortBy(tableData, ["fieldType"]);
};

export const transformTableToTask = (data) => {
  const task = {};

  data.forEach((field) => {
    if (!field.send) {
      return;
    }

    _.set(task, field.fieldType, field.value);
  });

  return task;
};

export const transformTaskToSandboxTask = (task: Task): Task => {
  if (task.way_points?.length) {
    // Null by default, otherwise Hagmonia will create fake dates. See: https://bringg.atlassian.net/browse/BRNGG-16672
    task.way_points = task.way_points.map((waypoint) => ({
      scheduled_at: null,
      no_later_than: null,
      no_earlier_than: null,
      ...waypoint,
    }));
  }

  return task;
};
