import { Driver as BringgDriver } from "@bringg/types";

export class Driver {
  id: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  external_id: string;
  lat: number;
  lng: number;
  name: string;

  constructor(driver: BringgDriver) {
    Object.assign(this, driver);
  }
}
