// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import { Task, TaskStatus, WayPoint } from "@bringg/types";
import _isNil from "lodash/isNil";
import _isNumber from "lodash/isNumber";

import late from "../../images/late.svg";
import started from "../../images/started.svg";
import unstarted from "../../images/unstarted.svg";

interface Props {
  task: Task;
}

const isTaskActive = (task: Task): boolean => {
  return [TaskStatus.OnTheWay, TaskStatus.CheckedIn].includes(task.status);
};

const getActiveWayPointPosition = (task: Task): number => {
  if (!_isNumber(task.active_way_point_id)) {
    return null;
  }

  const wayPoint = task.way_points.find(
    (wayPoint) => wayPoint.id === task.active_way_point_id
  );

  return _isNil(wayPoint) ? -1 : wayPoint.position;
};

const getImageForWayPoint = (wayPoint: WayPoint, task: Task): string => {
  const activeWayPointPosition = getActiveWayPointPosition(task);

  if (!wayPoint || !_isNumber(activeWayPointPosition)) {
    return unstarted;
  }

  if (wayPoint.late) {
    return late;
  }

  if (wayPoint.position < activeWayPointPosition) {
    return started;
  }

  if (
    wayPoint.position === activeWayPointPosition &&
    (isTaskActive(task) || wayPoint.done)
  ) {
    return started;
  }

  return unstarted;
};

export const StatusProgress: FunctionComponent<Props> = ({ task }) => {
  return (
    <span className="status-progress">
      <img
        className="first-way-point-progress"
        src={getImageForWayPoint(task.way_points[0], task)}
        alt=""
      />
      <img
        className="second-way-point-progress"
        src={getImageForWayPoint(task.way_points[1], task)}
        alt=""
      />
    </span>
  );
};
