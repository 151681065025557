// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { SideMenuContainer } from "@bringg/react-pages/dist/components/index";
import { RouteDetails } from "@bringg/react-pages/dist/components/pages/pages";
import { MenuItem } from "@bringg/react-pages/dist/components/side-menu-container/side-menu-container";
import { faClipboardCheck } from "@fortawesome/pro-solid-svg-icons/faClipboardCheck";
import { faCog } from "@fortawesome/pro-solid-svg-icons/faCog";
import { faTasks } from "@fortawesome/pro-solid-svg-icons/faTasks";
import { TFunction } from "i18next";
import { useObserver } from "mobx-react";

import { LOGIN_ROUTE } from "../../component/router/logged-out-router/logged-out-router";
import { WelcomePage } from "../../component/welcome-page/welcome-page";
import { useHasFeatureFlag } from "../../hooks/use-has-feature-flag";
import { useStore } from "../../stores/use-store";
import { Dispatch } from "../dispatch/dispatch";
import { OrderPage } from "../order-page/order-page";
import { Registration } from "../registration/registration-container";
import { Settings } from "../settings/settings";

type MenuItemWithRoute = MenuItem & RouteDetails;

const getMenuItems = (
  t: TFunction,
  newVersion: boolean
): MenuItemWithRoute[] => {
  const items = [
    {
      title: t("MENU_REGISTRATION"),
      icon: faClipboardCheck,
      key: "1",
      path: "registration",
      component: Registration,
    },
    {
      title: t("MENU_DISPATCH"),
      icon: faTasks,
      key: "2",
      path: "dispatch",
      component: Dispatch,
    },
  ];

  if (newVersion) {
    items.push({
      title: t("MENU_SETTINGS"),
      icon: faCog,
      key: "3",
      path: "settings",
      component: Settings,
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return items;
};

const getRoutesOutsideMenu = (): RouteDetails[] => {
  return [
    {
      path: "order/:id",
      component: OrderPage,
    },
  ];
};

export const MainPage: FunctionComponent = () => {
  const {
    uiStore: { authStore },
  } = useStore();
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const newVersion = useHasFeatureFlag("enable_partner_portal");
  const menuItems = getMenuItems(t, newVersion);
  const routes = [...getRoutesOutsideMenu(), ...menuItems];

  const onLogout = useCallback(() => {
    authStore.logout();
    history.push(`/#${LOGIN_ROUTE.path}`);
  }, [authStore, history]);

  return (
    <SideMenuContainer
      items={menuItems}
      logoutText={t("LOGOUT")}
      userEmail={authStore.user.email}
      userName={authStore.user.name}
      onLogout={onLogout}
    >
      <Switch>
        {routes.map((item) => {
          const mergedRoute = { ...item, path: `${path}${item.path}` };

          return <Route key={item.path} {...mergedRoute} />;
        })}
        <Route
          exact={true}
          path={path}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: `${path}${menuItems[0].path}`,
                state: { from: location },
              }}
            />
          )}
        />
      </Switch>
    </SideMenuContainer>
  );
};

export const PrevMainPage = () => {
  const {
    uiStore: { authStore },
  } = useStore();

  const newVersion = useHasFeatureFlag("enable_partner_portal");

  return useObserver(() =>
    newVersion && authStore.firstLogin ? (
      <WelcomePage setFirstLogin={authStore.changeFirstLogin} />
    ) : (
      <MainPage />
    )
  );
};
