// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Button from "@bringg/react-components/dist/components/button/button";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  name: string;
  id: number;
}

export const CertificationPopup: FunctionComponent<Props> = ({ name, id }) => {
  const { t } = useTranslation();

  return (
    <div className="certification-popup">
      <div className="certification-body">
        <span className="certification-warning">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        <span className="certification-body-title">
          {t("CERTIFICATE_TITLE")}:
        </span>
        <span className="certification-body-text">
          {t("CERTIFICATE_DESCRIPTION")}.
        </span>
        <Button className="certification-button" htmlType="submit">
          <a
            href={`mailto:product@bringg.com?subject=${name} - Request for getting certified (id ${id})`}
          >
            {t("CERTIFICATE_APPROVE")}
          </a>
        </Button>
        <a
          className="certification-learn-more-button"
          href="https://developers.bringg.com/docs/getting-certified"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("CERTIFICATE_LEARN_MORE")}
        </a>
      </div>
    </div>
  );
};
