// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { RouteDetails } from "@bringg/react-pages/dist/components/pages/pages";

import { Login } from "../../../features/login/login";
import { AppRoute } from "../route/route";

export const LoggedOutRouter: FunctionComponent = () => {
  return (
    <HashRouter basename="/">
      <Switch>
        {appRoutes.map((route) => (
          <AppRoute {...route} key={route.path} />
        ))}

        <Route
          render={(props) => (
            <Redirect
              to={{
                pathname: LOGIN_ROUTE.path,
                state: { from: props.location },
              }}
            />
          )}
        />
      </Switch>
    </HashRouter>
  );
};

export const LOGIN_ROUTE: RouteDetails = {
  path: "/login",
  component: Login,
};

const appRoutes: RouteDetails[] = [LOGIN_ROUTE];
