// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react";
import { useHistory } from "react-router";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Tabs } from "antd";
import { get } from "lodash";

import { LearnMoreButton } from "../../component/learn-more-button/learn-more-button";

import "./nice-container.scss";

interface NiceContainerProps {
  title: string;
  path: string;
  items: {
    key: string;
    tab: string;
    component: JSX.Element;
  }[];
}

const NiceContainer = ({
  title,
  path,
  items,
}: NiceContainerProps): JSX.Element => {
  const history = useHistory();
  const routeMatch = useRouteMatch(`/${path}/:page`);
  const activePage = get(routeMatch, "params.page");

  const onTabChange = (tab): void => {
    history.push(`/${path}/${tab}`);
  };

  return (
    <div className="nice-container">
      <h1 className="nice-container-title">{title}</h1>

      <div className="nice-container-tabs-wrapper">
        <Tabs
          className="nice-container-tabs"
          activeKey={activePage}
          onChange={onTabChange}
        >
          {items.map((item) => (
            <Tabs.TabPane key={item.key} tab={item.tab} />
          ))}
        </Tabs>

        <LearnMoreButton />
      </div>

      <Switch>
        {items.map((item) => (
          <Route key={`route-${item.key}`} path={`/${path}/${item.key}`}>
            {item.component}
          </Route>
        ))}
        <Redirect to={`/${path}/${items[0].key}`} />
      </Switch>
    </div>
  );
};

export { NiceContainer };
