import { Notifier } from "@airbrake/browser";

export const airbrake =
  process.env.NODE_ENV !== "test" &&
  process.env.REACT_APP_AIRBRAKE_ID &&
  process.env.REACT_APP_AIRBRAKE_KEY
    ? new Notifier({
        projectId: parseInt(process.env.REACT_APP_AIRBRAKE_ID),
        projectKey: process.env.REACT_APP_AIRBRAKE_KEY,
      })
    : null;
