// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import SelectWrapper, {
  OptionWrapper,
} from "@bringg/react-components/dist/components/select/select";
import { TFunction } from "i18next";
import { useObserver } from "mobx-react";

import { useStore } from "../../stores/use-store";

interface Props {
  taskId: number;
  fleetId: number;
}

const getSelectionOptions = (
  fleetId: number,
  fleetName: string,
  t: TFunction
): OptionWrapper[] => {
  return [
    {
      id: fleetId,
      name: fleetName,
      dropDownKey: fleetName,
    },
  ];
};

export const FleetSelect: FunctionComponent<Props> = ({ taskId, fleetId }) => {
  const {
    dataStore: { fleetSettingsStore, tasksStore },
  } = useStore();
  const { t } = useTranslation();

  return useObserver(() => (
    <span className="fleet-select-wrapper">
      <SelectWrapper
        options={getSelectionOptions(
          fleetSettingsStore.fleetId,
          fleetSettingsStore.name,
          t
        )}
        placeholder={t("UNASSIGNED_FLEET")}
        value={fleetId > 0 ? fleetId : undefined}
        onChange={(selectedFleetId) =>
          tasksStore.assignFleetToTask(taskId, selectedFleetId)
        }
      />
    </span>
  ));
};
