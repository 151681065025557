// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import { WayPoint } from "@bringg/types";
import _get from "lodash/get";
import moment from "moment";

const TIME_FORMAT = "HH:mm A";
const DATES_SEPARATOR = " - ";

interface Props {
  wayPoint: WayPoint;
}

export const TimeWindow: FunctionComponent<Props> = ({ wayPoint }) => {
  const noEarlierThan = _get(wayPoint, "no_earlier_than");
  const noLaterThan = _get(wayPoint, "no_later_than");

  return (
    <span className="time-window">
      {noEarlierThan ? moment(noEarlierThan).format(TIME_FORMAT) : ""}
      {DATES_SEPARATOR}
      {noLaterThan ? moment(noLaterThan).format(TIME_FORMAT) : ""}
    </span>
  );
};
