// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react";
import { useObserver } from "mobx-react";

import { LoggedInRouter } from "./component/router/logged-in-router/logged-in-router";
import { LoggedOutRouter } from "./component/router/logged-out-router/logged-out-router";
import { useStore } from "./stores/use-store";

import "./App.scss";

export function App() {
  const {
    uiStore: { authStore },
  } = useStore();

  return useObserver(() => (
    <div className="App">
      {authStore.isLoggedIn ? <LoggedInRouter /> : <LoggedOutRouter />}
    </div>
  ));
}
