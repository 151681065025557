import { Environment } from "@bringg/frontend-utils/dist/common/types";
import Authentication from "@bringg/frontend-utils/dist/services/authentication";
import DashboardSdk from "@bringg/frontend-utils/dist/services/dashboard-sdk";
import { wrapRoot } from "mobx-easy";

import { TranslationService } from "../services/translation-service";
import { RootStore } from "../stores/root-store";

export interface RootEnv {
  translationService: TranslationService;
  envConfig: Environment;
  dashboardSDK: DashboardSdk;
  authentication: Authentication;
}

export interface CreateStoreResult {
  rootStore: RootStore;
  env: RootEnv;
}

export interface CreateStoreOptions {
  envConfig: Environment;
  dashboardSDK: DashboardSdk;
  authentication: Authentication;
}

export const createStore = ({
  envConfig,
  dashboardSDK,
  authentication,
}: CreateStoreOptions): CreateStoreResult => {
  const env = {
    translationService: new TranslationService(),
    envConfig,
    dashboardSDK,
    authentication,
  };
  const rootStore = wrapRoot({ RootStore: RootStore, env });

  return {
    rootStore,
    env,
  };
};
