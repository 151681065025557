import { AdminCredentialsResponse } from "@bringg/dashboard-sdk/dist/OpenFleet/OpenFleet.consts";
import { action, computed, observable } from "mobx";
import { getEnv, getRoot } from "mobx-easy";

import { RootEnv } from "../../create-store";
import { RootStore } from "../../root-store";

export class AdminCredentialsStore {
  // eslint-disable-next-line @typescript-eslint/camelcase
  @observable client_id: string = null;
  // eslint-disable-next-line @typescript-eslint/camelcase
  @observable client_secret: string = null;
  @observable isFetched: boolean = false;

  public async fetch() {
    if (!this.isFetched) {
      const { dashboardSDK } = getEnv<RootEnv>();
      const adminCredentials: AdminCredentialsResponse =
        await dashboardSDK.sdk.openFleets.getAdminCredentials();

      Object.assign(this, adminCredentials);
      this.isFetched = true;
    }
  }

  @action
  public afterLogout() {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.client_id = null;
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.client_secret = null;
    this.isFetched = false;
  }

  @computed
  public get getEndpoint(): string {
    const {
      uiStore: { authStore },
      dataStore: { fleetSettingsStore },
    } = getRoot<RootStore>();

    return `${authStore.apiEndpoint}/open_fleet_administration/${fleetSettingsStore.uuid}`;
  }

  @computed
  public get clientId(): string {
    return this.client_id;
  }

  @computed
  public get secret(): string {
    return this.client_secret;
  }
}
