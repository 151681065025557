// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react";
import { Button, Input, notification, Tooltip } from "antd";

import copyIcon from "../../images/copy.svg";

import "./url-copier.scss";

interface UrlCopierProps {
  children: string;
  title?: string;
  type?: string;
  transparent?: boolean;
}

const UrlCopier = ({
  children,
  title,
  type = "URL",
  transparent = false,
}: UrlCopierProps): JSX.Element => {
  const onClick = (): void => {
    const dummyElement = document.createElement("input");

    document.body.appendChild(dummyElement);
    dummyElement.setAttribute("value", children);
    dummyElement.select();
    document.execCommand("copy");
    document.body.removeChild(dummyElement);

    notification.success({
      message: "Copied to clipboard",
      placement: "bottomRight",
    });
  };

  if (transparent) {
    return (
      <Tooltip title="Copy to clipboard">
        <Button
          type="link"
          icon={<img alt="copy icon" src={copyIcon} />}
          onClick={onClick}
        />
      </Tooltip>
    );
  }

  return (
    <div className="url-copier">
      {title && <div className="url-copier-title">{title}</div>}
      <Input
        addonBefore={type}
        disabled={true}
        suffix={
          <Tooltip title="Copy to clipboard">
            <Button
              type="link"
              icon={<img alt="copy icon" src={copyIcon} />}
              onClick={onClick}
            />
          </Tooltip>
        }
        value={children}
      />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default UrlCopier;
