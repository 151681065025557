// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Row from "@bringg/react-components/dist/components/row/row";
import TableWrapper from "@bringg/react-components/dist/components/table/table";
import { TFunction } from "i18next";
import { useObserver } from "mobx-react";

import { CertificationPopup } from "../../../component/certification-popup/certification-popup";
import { SettingHeader } from "../../../component/setting-header/setting-header";
import { useStore } from "../../../stores/use-store";
import { AdminCredentials } from "./admin-credentials/admin-credentials";
import { AdminEndpoints } from "./admin-endpoints/admin-endpoints";
import { HiddenCell } from "./hidden-cell/hidden-cell";
import { MerchantCell } from "./merchant-cell/merchant-cell";

const renderCell = (text: string) => <MerchantCell text={text} />;

const renderHiddenCell = (text: string) => <HiddenCell text={text} />;

const getColumns = (t: TFunction) => {
  return [
    {
      title: t("MERCHANT_NAME_COLUMN"),
      dataIndex: "merchant_name",
    },
    {
      title: t("CLIENT_ID_COLUMN"),
      dataIndex: "credentials.client_id",
      render: renderCell,
    },
    {
      title: t("CLIENT_SECRET_COLUMN"),
      render: renderHiddenCell,
      dataIndex: "credentials.client_secret",
    },
    {
      title: t("API_KEY_COLUMN"),
      dataIndex: "credentials.api_key",
      render: renderHiddenCell,
    },
    {
      title: t("END_POINT_PREFIX"),
      dataIndex: "api_endpoint",
      render: renderCell,
    },
  ];
};

export const MerchantsList: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    dataStore: { registeredMerchantsStore, fleetSettingsStore },
  } = useStore();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      await registeredMerchantsStore.fetch();
      await fleetSettingsStore.fetch();
      setLoading(false);
    };

    init();
  }, [registeredMerchantsStore, fleetSettingsStore]);

  return useObserver(() => (
    <div>
      <Row className="merchants-list" type="flex" justify="space-around">
        {!fleetSettingsStore.isApproved && (
          <CertificationPopup
            name={fleetSettingsStore.name}
            id={fleetSettingsStore.id}
          />
        )}
        <div className="setting-body">
          <SettingHeader
            title={t("MERCHANTS_LIST_TITLE")}
            description={t("MERCHANTS_LIST_DESCRIPTION")}
          />
          <div className="sub-title">{t("MERCHANTS_LIST_SUB_TITLE")}</div>
          <TableWrapper
            useFixedHeader={true}
            collection={registeredMerchantsStore.registeredMerchants}
            loading={isLoading}
            columns={getColumns(t)}
            rowKey="merchant_name"
          />
          <div className="sub-title">{t("ADMIN_ENDPOINTS_SUB_TITLE")}</div>
          <AdminEndpoints loading={isLoading} />
          <div className="sub-title">{t("ADMIN_CREDENTIALS_SUB_TITLE")}</div>
          <AdminCredentials />
        </div>
      </Row>
    </div>
  ));
};
