// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import CopyToClipboard from "@bringg/react-components/dist/components/copy-to-clipboard/copy-to-clipboard";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface Props {
  text: string;
}

export const MerchantCell: FunctionComponent<Props> = ({ text }) => {
  return (
    <>
      {text ? (
        <span className="merchant-cell">
          <span className="column-text">{text}</span>
          <CopyToClipboard showText={false} text={text}>
            <FontAwesomeIcon className="copy-to-clipboard-icon" icon={faCopy} />
          </CopyToClipboard>
        </span>
      ) : null}
    </>
  );
};
