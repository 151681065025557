// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import WayPointCard from "@bringg/react-components/dist/features/waypoint-card/wapoint-card";
import { WayPoint } from "@bringg/types";
import { useObserver } from "mobx-react";

import { useHasFeatureFlag } from "../../../hooks/use-has-feature-flag";
import { Task } from "../../../stores/data/tasks/task";
import { useStore } from "../../../stores/use-store";
import { getWayPointTranslations } from "./way-points-translations";

interface Props {
  task: Task;
}

export const WayPoints: FunctionComponent<Props> = ({ task }) => {
  const { t } = useTranslation();
  const newVersion = useHasFeatureFlag("enable_partner_portal");
  const {
    uiStore: { authStore },
    dataStore: { tasksStore },
  } = useStore();

  const onSave = (wayPointToSave: WayPoint) => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    tasksStore.update(task.id, { id: task.id, way_points: [wayPointToSave] });
  };

  return useObserver(() => (
    <div className="way-points">
      {task.way_points.map((wayPoint) => (
        <WayPointCard
          key={wayPoint.id}
          timeZone={authStore.merchant.time_zone}
          translations={getWayPointTranslations(t)}
          wayPoint={wayPoint}
          taskNotes={task.task_notes.filter(
            (note) => note.way_point_id === wayPoint.id
          )}
          taskInventories={task.task_inventories.filter(
            (inventory) => inventory.way_point_id === wayPoint.id
          )}
          editable={newVersion}
          onSave={onSave}
        />
      ))}
    </div>
  ));
};
