import React from "react";

import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps,
} from "react-error-boundary";

import { Alert, Button } from "antd";

import { useAirbrake } from "../../hooks/use-airbrake";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert" className="error-container">
      <div className="error-row">
        <Alert
          type="error"
          message="Global Error"
          description={error.message}
        />
      </div>
      <div className="error-row">
        <Button onClick={resetErrorBoundary}>"Global Error Reload"</Button>
      </div>
    </div>
  );
}

const ErrorBoundary = ({ children }) => {
  const airbrake = useAirbrake();

  const onError: ErrorBoundaryProps["onError"] = (error, info) => {
    airbrake?.notify({
      error,
      params: { info },
    });
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
};

export { ErrorBoundary };
