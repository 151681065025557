// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@bringg/react-components/dist/components/button/button";
import { notification } from "antd";
import { useObserver } from "mobx-react";

import { useHasFeatureFlag } from "../../../hooks/use-has-feature-flag";
import { useStore } from "../../../stores/use-store";
import defaultTask from "../task-mapper/defaultTask.json";
import { TaskMapper } from "../task-mapper/task-mapper";
import { transformTaskToSandboxTask } from "../task-mapper/transformData";

export const CreateTaskButton = (): JSX.Element => {
  const {
    dataStore: { tasksStore },
  } = useStore();
  const newVersion = useHasFeatureFlag("enable_partner_portal");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const onOk = async (task) => {
    await createTaskForSandbox(task);
    setIsModalOpen(false);
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const createTaskForSandbox = useCallback(
    async (task = {}) => {
      try {
        setLoading(true);
        await tasksStore.createTaskForSandbox(transformTaskToSandboxTask(task));
        notification.success({
          message: t("CREATE_ORDER_SUCCESS"),
          placement: "bottomRight",
        });
      } catch (error) {
        notification.error({
          message: t("CREATE_ORDER_ERROR"),
          placement: "bottomRight",
        });
      } finally {
        setLoading(false);
      }
    },
    [tasksStore, t]
  );

  const createTask = useCallback(async () => {
    setLoading(true);

    try {
      newVersion ? openModal() : await createTaskForSandbox();
    } finally {
      setLoading(false);
    }
  }, [createTaskForSandbox, newVersion]);

  return useObserver(() => (
    <>
      <Button
        className="create-task-button"
        loading={loading}
        onClick={createTask}
      >
        {t("CREATE_ORDER")}
      </Button>

      <TaskMapper
        title="Delivery Created API"
        subTitle="Paste your own order creation payload in JSON format below."
        isModalOpen={isModalOpen}
        loading={loading}
        taskProp={defaultTask}
        onCancel={onCancel}
        onOk={onOk}
      />
    </>
  ));
};
