// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent, useState } from "react";
import { Controller, UseFormMethods } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Input from "@bringg/react-components/dist/components/floating-placeholder-input/input";
import Row from "@bringg/react-components/dist/components/row/row";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  FleetEndpointType,
  FleetSettings,
  WebhookOption,
  WebhookTypeToOptions,
} from "../../../../../stores/data/fleet-settings/fleet-settings";

export interface Props {
  form: UseFormMethods<FleetSettings>;
  defaultValues?: FleetSettings;
}

const isOptional = (webhookType: FleetEndpointType): boolean => {
  return WebhookTypeToOptions[webhookType] === WebhookOption.Optional;
};

const isOrderUpdate = (webhookType: FleetEndpointType): boolean => {
  return webhookType === FleetEndpointType.UpdateOrder;
};

export const WebhooksListForm: FunctionComponent<Props> = ({
  form,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const { control } = form;
  const [fullPayloadChecked, setFullPayloadChecked] = useState(
    defaultValues.fullPayloadOnUpdate
  );

  const handleAllowPayloadUpdateChange = (event, onChange) => {
    const checked = event.target.checked;

    setFullPayloadChecked(checked);
    onChange(checked);
  };

  return (
    <div className="webhooks-list-container">
      {Object.values(FleetEndpointType).map((webhookType) => {
        return (
          <div>
            <Row key={webhookType as string} className="webhook-row">
              <Controller
                render={({ value, onChange }) => (
                  <div>
                    <Input
                      value={value}
                      placeholder={
                        <span>
                          {t(webhookType)}
                          {isOptional(webhookType) ? (
                            <span className="webhook-optional">
                              {" "}
                              {t("WEBHOOKS_OPTIONAL")}
                            </span>
                          ) : (
                            <span className="webhook-mandatory"> *</span>
                          )}
                        </span>
                      }
                      onChange={onChange}
                    />
                  </div>
                )}
                control={control}
                name={`endpoints.${webhookType}`}
                defaultValue={defaultValues.endpoints[webhookType]}
              />
              {isOrderUpdate(webhookType) && (
                <Controller
                  render={({ onChange }) => (
                    <div className="full-payload-on-update">
                      <input
                        className="checkbox"
                        type="checkbox"
                        checked={fullPayloadChecked}
                        onChange={(event) =>
                          handleAllowPayloadUpdateChange(event, onChange)
                        }
                      />
                      <label className="checkbox-label">
                        {t("WEBHOOKS_GET_FULL_PAYLOAD_UPDATE")}
                      </label>
                      <FontAwesomeIcon
                        className="question-icon"
                        icon={faQuestionCircle}
                        title={t("WEBHOOKS_GET_FULL_PAYLOAD_UPDATE_TOOLTIP")}
                      />
                    </div>
                  )}
                  control={control}
                  name="fullPayloadOnUpdate"
                  defaultValue={defaultValues.fullPayloadOnUpdate}
                />
              )}
            </Row>
          </div>
        );
      })}
    </div>
  );
};
