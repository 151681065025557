// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import { Route } from "react-router-dom";
import { RouteDetails } from "@bringg/react-pages/dist/components/pages/pages";

interface Props extends RouteDetails {
  [key: string]: any;
}

export const AppRoute: FunctionComponent<Props> = ({
  exact,
  routes,
  redirectRoute,
  path,
  predicate,
  component: Component,
  ...restProps
}) => {
  return (
    <Route
      key={path}
      exact={exact}
      path={path}
      render={(props) => {
        return (
          <Route
            {...restProps}
            render={(props) => (
              <Component routes={routes} {...restProps} {...props} />
            )}
          />
        );
      }}
    />
  );
};
