// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent, useEffect } from "react";
import Row from "@bringg/react-components/dist/components/row/row";
import { FormPage } from "@bringg/react-pages/dist/components/index";
import { useObserver } from "mobx-react";

import { useHasFeatureFlag } from "../../../hooks/use-has-feature-flag";
import { FleetSettings } from "../../../stores/data/fleet-settings/fleet-settings";
import { useStore } from "../../../stores/use-store";
import { EndpointsList } from "../endpoints-list/endpoints-list";
import { WebhooksApisForm } from "./webhooks-apis-form/webhooks-apis-form";

export const WebhooksApisSettings: FunctionComponent<{}> = () => {
  const {
    dataStore: { fleetSettingsStore },
  } = useStore();
  const newVersion = useHasFeatureFlag("enable_partner_portal");

  const onSubmit = async (settings: FleetSettings): Promise<FleetSettings> => {
    fleetSettingsStore.setEndpoints(settings.endpoints);
    fleetSettingsStore.setSandboxEndpoints(settings.sandbox_endpoints);
    fleetSettingsStore.setFullPayloadOnUpdate(settings.fullPayloadOnUpdate);
    await fleetSettingsStore.updateFleetSetting();

    return onInitValues();
  };

  const onInitValues = async (): Promise<FleetSettings> => {
    await fleetSettingsStore.fetch();

    return fleetSettingsStore.settings;
  };

  useEffect(() => {
    const init = async () => {
      await fleetSettingsStore.fetch();
    };

    init();
  }, [fleetSettingsStore]);

  return useObserver(() =>
    newVersion ? (
      <EndpointsList
        settings={fleetSettingsStore?.settings}
        type="webhooks"
        onSave={onSubmit}
      />
    ) : (
      <Row className="webhooks-settings">
        <FormPage
          component={WebhooksApisForm}
          onSubmit={onSubmit}
          onInitValues={onInitValues}
        />
      </Row>
    )
  );
};
