import { RegisteredMerchantsResponse } from "@bringg/dashboard-sdk/dist/OpenFleet/OpenFleet.consts";
import _isNil from "lodash/isNil";
import { action, IObservableArray, observable } from "mobx";
import { getEnv, getRoot } from "mobx-easy";

import { RootEnv } from "../../create-store";
import { RootStore } from "../../root-store";
import { RegisteredMerchant } from "./registered-merchant";

export class RegisteredMerchantsStore {
  @observable isFetched: boolean = false;
  @observable.shallow
  registeredMerchants: IObservableArray<RegisteredMerchant> = [] as IObservableArray<RegisteredMerchant>;

  public async fetch() {
    if (!this.isFetched) {
      const { dashboardSDK } = getEnv<RootEnv>();
      const {
        dataStore: { fleetSettingsStore },
      } = getRoot<RootStore>();

      if (_isNil(fleetSettingsStore.settings)) {
        await fleetSettingsStore.fetch();
      }

      const templateUuid = fleetSettingsStore.settings.uuid;
      const registeredMerchants: RegisteredMerchantsResponse =
        await dashboardSDK.sdk.openFleets.getAllMerchantsCredentials(
          templateUuid
        );

      registeredMerchants.forEach((merchant) =>
        this.registeredMerchants.push(new RegisteredMerchant(merchant))
      );

      this.isFetched = true;
    }
  }

  @action
  public afterLogout() {
    this.registeredMerchants.clear();
    this.isFetched = false;
  }
}
