// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@bringg/react-components/dist/components/button/button";
import { notification } from "antd";
import { useObserver } from "mobx-react";

import { useStore } from "../../../stores/use-store";
import defaultQuote from "../task-mapper/defaultQuote.json";
import { TaskMapper } from "../task-mapper/task-mapper";

export const GetQuoteButton = (): JSX.Element => {
  const {
    dataStore: { fleetSettingsStore },
  } = useStore();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const quoteUrl = fleetSettingsStore.settings?.sandbox_endpoints.get_quote_url;

  const onClick = (): void => {
    setIsModalOpen(true);
  };

  const onOk = async (diff): Promise<void> => {
    try {
      setIsModalOpen(false);

      const { data } = await fleetSettingsStore.getQuotes(diff);

      if (data.success) {
        notification.success({
          message: t("GET_QUOTE_SUCCESS"),
          placement: "bottomRight",
        });
      } else {
        notification.error({
          message: data.error,
          placement: "bottomRight",
        });
      }
    } catch (error) {
      notification.error({
        message: t("GET_QUOTE_ERROR"),
        placement: "bottomRight",
      });
    }
  };

  const onCancel = (): void => {
    setIsModalOpen(false);
  };

  return useObserver(() =>
    quoteUrl ? (
      <>
        <Button className="quote-task-button" onClick={onClick}>
          {t("GET_QUOTE")}
        </Button>

        <TaskMapper
          title="Get Quote test"
          subTitle="Paste your quote payload in JSON format below."
          isModalOpen={isModalOpen}
          taskProp={defaultQuote}
          onCancel={onCancel}
          onOk={onOk}
        />
      </>
    ) : null
  );
};
