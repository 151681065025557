// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useState } from "react";
import ReCaptcha from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, message, notification, Select } from "antd";
import { getEnv } from "mobx-easy";

import { RootEnv } from "../../stores/create-store";
import { useStore } from "../../stores/use-store";

export const Login = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    uiStore: { authStore },
  } = useStore();
  // eslint-disable-next-line @typescript-eslint/camelcase
  const { recaptcha_site_key } = getEnv<RootEnv>().envConfig;

  const [loading, setLoading] = useState(false);
  const [merchantOptions, setMerchantOptions] = useState<any[]>([]);
  const [recaptchaSToken, setRecaptchaSToken] = useState<string | null>(null);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const response = await authStore.attemptToLogin(
        values.userName,
        values.password,
        merchantOptions
          ? merchantOptions.find(({ uuid }) => uuid === values.merchantUuid)
          : undefined,
        values.captchaValue
      );

      if (response.success) {
        history.push("registration");
      } else {
        setLoading(false);

        const { data } = response;

        if (data.recaptcha_stoken) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (recaptcha_site_key) {
            setRecaptchaSToken(data.recaptcha_stoken);
          } else {
            message.error("Recaptcha is requested. But site key is missing");
          }
        } else if (data.merchants_data) {
          setMerchantOptions(data.merchants_data);
          setRecaptchaSToken(null);

          form.setFieldsValue({
            merchantUuid: data.merchants_data[0].uuid,
          });
        }
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Failed to login",
        description: "Could not login to fleet app",
        placement: "bottomRight",
      });

      setMerchantOptions([]);
      setRecaptchaSToken(null);
    }
  };

  return (
    <div className="login">
      <div className="login-logo" />

      <div className="login-form">
        <Form form={form} onFinish={onFinish}>
          <h1>Bringg Integrations Portal</h1>
          <p>Get your credentials from your Bringg representative.</p>

          <Form.Item
            name="userName"
            label="User name"
            rules={[
              { required: true, message: "Required" },
              { max: 100, message: "Exceeded max length of 100 symbols" },
              { type: "email", message: "Should be a valid email" },
            ]}
          >
            <Input size="large" disabled={loading} />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: "Required" },
              { max: 100, message: "Exceeded max length of 100 symbols" },
            ]}
          >
            <Input.Password
              disabled={loading}
              iconRender={(visible) => (visible ? "Hide" : "Show")}
            />
          </Form.Item>

          {Boolean(merchantOptions.length) && (
            <Form.Item
              name="merchantUuid"
              label="Merchant"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select size="large" disabled={loading}>
                {merchantOptions.map(({ uuid, name }) => (
                  <Select.Option key={uuid} value={uuid}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {/* eslint-disable-next-line @typescript-eslint/camelcase */}
          {Boolean(recaptchaSToken && recaptcha_site_key) && (
            <Form.Item
              name="captchaValue"
              rules={[{ required: true, message: "Required" }]}
            >
              {/* eslint-disable-next-line @typescript-eslint/camelcase */}
              <ReCaptcha sitekey={recaptcha_site_key} />
            </Form.Item>
          )}

          <a href="https://app.bringg.com/#/remind/" className="login-forget">
            Forgot your password?
          </a>

          <Button
            type="primary"
            htmlType="submit"
            block={true}
            size="large"
            disabled={loading}
            loading={loading}
          >
            Log In
          </Button>
        </Form>
      </div>
    </div>
  );
};
