import { observable } from "mobx";

import { FleetApiService } from "./fleet-settings-store";

export class ServiceEndpoint {
  @observable type: string;
  @observable url: string;

  constructor(serviceEndpoint: FleetApiService) {
    Object.assign(this, serviceEndpoint);
  }
}
