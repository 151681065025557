// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import Mapbox from "@bringg/react-components/dist/components/mapbox/mapbox";
import Spinner from "@bringg/react-components/dist/components/spinner/spinner";
import _get from "lodash/get";
import { useObserver } from "mobx-react";

import { useStore } from "../../../stores/use-store";

const DEFAULT_LAT = 33.5957511;
const DEFAULT_LNG = -101.9430972;
const DEFAULT_ZOOM = 10;
const MAPBOX_OPTIONS = {
  style: "mapbox://styles/bringg/cl0taf34y00q014qbypr0bbm9",
};

interface Props {
  loading: boolean;
}

export const DispatchMap: FunctionComponent<Props> = ({ loading }) => {
  const {
    viewStore: { mapViewStore },
    uiStore: { authStore },
  } = useStore();
  const center = {
    lat: _get(authStore.merchant, "lat", DEFAULT_LAT),
    lng: _get(authStore.merchant, "lng", DEFAULT_LNG),
  };

  return useObserver(() => (
    <div className="map-container">
      {loading ? (
        <Spinner className="spinner" />
      ) : (
        <Mapbox
          apiKey={authStore.mapboxAccessToken}
          zoom={DEFAULT_ZOOM}
          center={center}
          markers={[
            ...mapViewStore.tasksMarkers,
            ...mapViewStore.driversMarkers,
          ]}
          mapboxOptions={MAPBOX_OPTIONS}
          className="mapbox-container"
        />
      )}
    </div>
  ));
};
