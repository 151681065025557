// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import { UseFormMethods } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CopyButton } from "../../../../../component/copy-button/copy-button";
import { FleetSettings } from "../../../../../stores/data/fleet-settings/fleet-settings";

export interface Props {
  form: UseFormMethods<FleetSettings>;
  defaultValues?: FleetSettings;
}

export const WebhookKey: FunctionComponent<Props> = ({
  form,
  defaultValues,
}) => {
  const { t } = useTranslation();

  return (
    <div className="key-container">
      {defaultValues && (
        <div>
          <span className="webhook-key">{defaultValues.key}</span>
          <CopyButton
            textToCopy={defaultValues.key}
            buttonText={t("COPY_KEY_TEXT")}
          />
        </div>
      )}
      <div className="key-description">{t("WEBHOOK_KEY_DESCRIPTION")}</div>
    </div>
  );
};
