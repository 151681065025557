// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Button } from "antd";

import { LEARN_MORE_LINKS } from "../../consts";
import arrowUpRightIcon from "../../images/arrow-up-right.svg";

import "./learn-more-button.scss";

const LearnMoreButton = (): JSX.Element => {
  const match = useRouteMatch(Object.keys(LEARN_MORE_LINKS));

  if (!match) {
    return null;
  }

  return (
    <Button
      className="learn-more-button"
      type="link"
      target="_blank"
      href={LEARN_MORE_LINKS[match.path]}
    >
      Learn more <img alt="learn more button" src={arrowUpRightIcon} />
    </Button>
  );
};

export { LearnMoreButton };
