// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react";
import { Popup } from "@bringg/react-components/dist/types/maps.consts";
import { Driver, Task } from "@bringg/types";
import { faCar } from "@fortawesome/free-solid-svg-icons/faCar";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons/faMapMarker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const taskMarkerIcon = <FontAwesomeIcon size="2x" icon={faMapMarker} />;
export const driverMarkerIcon = <FontAwesomeIcon size="2x" icon={faCar} />;

export const getMarkerPopup = (entity: Task | Driver): Popup => {
  return {
    element: (
      <>
        <div>ID : {entity.id}</div>
        <div>External ID : {entity.external_id}</div>
      </>
    ),
    offset: { x: 0, y: 12 },
    anchor: "top",
  };
};
