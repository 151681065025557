import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";

export class TranslationService {
  public translate(text: string, ...args: any[]) {
    return i18next.t(text, args);
  }

  public async init() {
    return i18next
      .use(Backend)
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        fallbackLng: "en",
        returnEmptyString: false,
        debug: false,
        ns: "default",
        keySeparator: " ",
        backend: {
          loadPath: "/locale/{{lng}}.json",
        },
        lowerCaseLng: true,
        interpolation: {
          escapeValue: false,
        },
        react: {
          useSuspense: false,
        },
        fallbackNS: "default",
      });
  }
}
