// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent, ReactNode } from "react";

interface Props {
  title: ReactNode | string;
  description?: string | JSX.Element;
}

export const SettingHeader: FunctionComponent<Props> = ({
  title,
  description,
}) => {
  return (
    <div className="setting-header">
      <div className="setting-header-title">{title}</div>
      {description && (
        <div className="setting-header-description">{description}</div>
      )}
    </div>
  );
};
