// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@bringg/react-components/dist/components/button/button";

import { MerchantCell } from "../merchant-cell/merchant-cell";

interface Props {
  text: string;
}

export const HiddenCell: FunctionComponent<Props> = ({ text }) => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(true);

  const show = useCallback(() => {
    setHidden(false);
  }, []);

  return (
    <>
      {hidden ? (
        <Button className="ask-for-text" onClick={show}>
          {t("SHOW_TEXT")}
        </Button>
      ) : (
        <MerchantCell text={text} />
      )}
    </>
  );
};
