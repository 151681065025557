import React from "react";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

// Fix copy-paste behavior (removes \r \n symbols). See https://bringg.atlassian.net/browse/BRNGG-15976
JSONInput.prototype.onPaste = function (event) {
  event.preventDefault();

  let text = event.clipboardData.getData("text/plain");

  try {
    text = JSON.stringify(JSON.parse(text));
  } catch (error) {
    // ignore error
  }

  document.execCommand("insertText", false, text);
  this.update();
};

const JsonEditor = ({ placeholder, onChange }) => {
  return (
    <JSONInput
      placeholder={placeholder}
      colors={{
        keys: "#3091CE",
        string: "#000",
        background: "#FFFFFF", // overrides theme colors with whatever color value you want
      }}
      locale={locale}
      height="375px"
      width="auto"
      onChange={onChange}
    />
  );
};

export default JsonEditor;
