import { MapboxMarkerData } from "@bringg/react-components/dist/components/mapbox/mapbox";
import { Driver, Task } from "@bringg/types";
import _isNil from "lodash/isNil";
import _isNumber from "lodash/isNumber";
import { action, computed, observable } from "mobx";

import {
  driverMarkerIcon,
  getMarkerPopup,
  taskMarkerIcon,
} from "./map-view.consts";

export class MapViewStore {
  @observable.shallow tasksMarkersById: Map<number, MapMarker> = new Map();
  @observable.shallow driversMarkersById: Map<number, MapMarker> = new Map();

  @computed
  public get driversMarkers(): MapMarker[] {
    return Array.from(this.driversMarkersById.values());
  }

  @computed
  public get tasksMarkers(): MapMarker[] {
    return Array.from(this.tasksMarkersById.values());
  }

  convertTaskToMarker = (task: Task): MapMarker => {
    let wayPointToUse = task.way_points[0];

    if (task.way_points[0]?.checkout_time && task.way_points.length > 1) {
      wayPointToUse = task.way_points[1];
    }

    if (!_isNumber(wayPointToUse?.lat) || !_isNumber(wayPointToUse?.lng)) {
      return null;
    }

    return new MapMarker({
      id: task.id,
      location: { lat: wayPointToUse.lat, lng: wayPointToUse.lng },
      icon: taskMarkerIcon,
      popup: getMarkerPopup(task),
    });
  };

  convertDriverToMarker = (driver: Driver): MapMarker => {
    if (!_isNumber(driver.lat) || !_isNumber(driver.lng)) {
      return null;
    }

    return new MapMarker({
      id: driver.id,
      location: { lat: driver.lat, lng: driver.lng },
      icon: driverMarkerIcon,
      popup: getMarkerPopup(driver),
    });
  };

  @action
  public setDriverMarker(driver: Driver): void {
    const driverMarker = this.convertDriverToMarker(driver);

    if (!_isNil(driverMarker)) {
      this.driversMarkersById.set(driver.id, driverMarker);
    }
  }

  @action
  public setTaskMarker(task: Task): void {
    const taskMarker = this.convertTaskToMarker(task);

    if (!_isNil(taskMarker)) {
      this.tasksMarkersById.set(task.id, taskMarker);
    }
  }

  @action
  public deleteDriverMarker(driver: Driver): void {
    this.driversMarkersById.delete(driver.id);
  }

  @action
  public deleteTaskMarker(task: Task): void {
    this.tasksMarkersById.delete(task.id);
  }

  public afterLogin(): void {
    this.driversMarkersById = new Map();
    this.tasksMarkersById = new Map();
  }
}

export class MapMarker {
  id: number;
  location: { lat: number; lng: number };

  constructor(mapMarker: MapboxMarkerData) {
    Object.assign(this, mapMarker);
  }
}
