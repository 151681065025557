// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Row from "@bringg/react-components/dist/components/row/row";
import { ComponentWithForm } from "@bringg/react-pages/dist/components";

import { CertificationPopup } from "../../../../component/certification-popup/certification-popup";
import { SettingHeader } from "../../../../component/setting-header/setting-header";
import { FleetSettings } from "../../../../stores/data/fleet-settings/fleet-settings";
import { WebhookKey } from "./webhook-key/webhook-key";
import { WebhooksListForm } from "./webhooks-list/webhooks-list";

export const WebhooksApisForm: FunctionComponent<
  ComponentWithForm<FleetSettings>
> = ({ form, defaultValues }) => {
  const { t } = useTranslation();

  return (
    <Row type="flex" justify="space-around" className="webhooks-apis-form">
      {!defaultValues.isApproved && (
        <CertificationPopup name={defaultValues.name} id={defaultValues.id} />
      )}
      <div className="setting-body">
        <SettingHeader
          title={t("WEBHOOKS_SETTING_TITLE")}
          description={t("WEBHOOKS_SETTING_DESCRIPTION")}
        />
        <div className="sub-title">{t("WEBHOOKS_SUB_TITLE")}</div>
        <WebhooksListForm defaultValues={defaultValues} form={form} />
        <div className="sub-title">{t("WEBHOOKS_KEY_SUB_TITLE")}</div>
        <WebhookKey form={form} defaultValues={defaultValues} />
      </div>
    </Row>
  );
};
