// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Button from "@bringg/react-components/dist/components/button/button";
import { useObserver } from "mobx-react";

import { CopyButton } from "../../../../component/copy-button/copy-button";
import { useStore } from "../../../../stores/use-store";

export const AdminCredentials: FunctionComponent = () => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(true);

  const {
    dataStore: { adminCredentialsStore },
  } = useStore();

  useEffect(() => {
    const init = async () => {
      await adminCredentialsStore.fetch();
    };

    init();
  }, [adminCredentialsStore]);

  const showCredentials = useCallback(() => {
    setHidden(false);
  }, [setHidden]);

  return useObserver(() => (
    <div className="admin-credentials">
      {hidden ? (
        <Button className="get-data-button" onClick={showCredentials}>
          {t("SHOW_ADMIN_CREDENTIALS")}
        </Button>
      ) : (
        <>
          <div className="client-id-container">
            <span className="text-field">{adminCredentialsStore.clientId}</span>
            <CopyButton
              textToCopy={adminCredentialsStore.clientId}
              buttonText={t("COPY_CLIENT_ID_TEXT")}
            />
          </div>
          <div>
            <span className="text-field">{adminCredentialsStore.secret}</span>
            <CopyButton
              textToCopy={adminCredentialsStore.secret}
              buttonText={t("COPY_SECRET_TEXT")}
            />
          </div>
          <div className="description">
            {t("ADMIN_CREDENTIALS_DESCRIPTION")}
          </div>
        </>
      )}
    </div>
  ));
};
