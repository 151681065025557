// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useRef } from "react";
import { Button, Tooltip } from "antd";
import { QuestionMark } from "../question-mark/question-mark";

type Step = {
  title: string;
  description: string;
  tooltip: string;
  specification?: string;
  additionalBackground?: boolean;
};

type DeliveryModel = {
  title: string;
  tooltip: string;
  steps: Step[];
};

type WelcomePageProps = {
  setFirstLogin: (value: boolean) => void;
};

const standartFlow: Step[] = [
  {
    title: "Get Quote",
    description: "Webhook",
    tooltip: "Request a DP price quote",
  },
  {
    title: "*Delivery Created",
    description: "Webhook",
    tooltip: "Bringg asks DP to create delivery",
  },
  {
    title: "*Assign Driver",
    description: "API Request",
    tooltip: "DP assigns a driver",
  },
  {
    title: "*Start Order",
    description: "API Request",
    tooltip: "Driver begins delivery",
  },
  {
    title: "Check in",
    description: "API Request",
    tooltip: "Arrival at pickup",
  },
  {
    title: "Check Out",
    specification: "(pickup WP)",
    description: "API Request",
    tooltip: "Departure from pickup",
  },
  {
    title: "Check in",
    description: "API Request",
    tooltip: "Arrival at pickup",
  },
  {
    title: "*Complete Order",
    specification: "(Drop off WP)",
    description: "API Request",
    tooltip: "Delivery is fulfilled",
  },
];

const cancelationFlow: Step[] = [
  {
    title: "Get Quote",
    description: "Webhook",
    tooltip: "Request a DP price quote",
  },
  {
    title: "*Delivery Created",
    description: "Webhook",
    tooltip: "Bringg asks DP to create delivery",
  },
  {
    title: "*Assign Driver",
    description: "API Request",
    tooltip: "DP assigns a driver",
  },
  {
    title: "*Start Order",
    description: "API Request",
    tooltip: "Driver begins delivery",
  },
  {
    title: "Check in",
    description: "API Request",
    tooltip: "Arrival at pickup",
  },
  {
    title: "*Delivery Canceled",
    description: "API Request",
    tooltip: "Delivery canceled by DP",
  },
];

const returnToStoreFlow: Step[] = [
  {
    title: "*Delivery Canceled",
    description: "API Request",
    tooltip: "Delivery canceled by DP",
  },
  {
    title: "*Return Delivery Created",
    description: "Webhook",
    tooltip: "Return item to fulfillment center",
  },
  {
    title: "*Start Order",
    description: "API Request",
    tooltip: "Driver begins return",
  },
  {
    title: "Check in",
    description: "API Request",
    tooltip: "Arrival at pickup",
  },
  {
    title: "*Complete Order",
    description: "API Request",
    tooltip: "Delivery is fulfilled",
  },
];

const bulkPickupDropoffFlow: Step[] = [
  {
    title: "*Assign Driver",
    description: "API Request",
    tooltip: "DP assigns a driver",
  },
  {
    title: "Cluster checkin",
    description: "API Request",
    tooltip: "Driver arrives at cluster pickup",
    additionalBackground: true,
  },
  {
    title: "Cluster Checkout",
    description: "API Request",
    tooltip: "Driver leaves cluster pickup",
    additionalBackground: true,
  },
  {
    title: "*Start Order",
    description: "API Request",
    tooltip: "Driver begins delivery",
  },
  {
    title: "Check in",
    description: "API Request",
    tooltip: "Arrival at dropoff",
  },
  {
    title: "*Complete Order",
    description: "API Request",
    tooltip: "Delivery is fulfilled",
  },
];

const deliveryModels: DeliveryModel[] = [
  {
    title: "Standard flow",
    tooltip: "Use a standard flow for typical deliveries",
    steps: standartFlow,
  },
  {
    title: "Cancelation Flow",
    tooltip:
      "Use a cancelation flow like the one below when a driver is unable to complete the delivery",
    steps: cancelationFlow,
  },
  {
    title: "Return to store flow",
    tooltip:
      "Use a return flow if the shipper asks that your driver return the undeliverable item to the store",
    steps: returnToStoreFlow,
  },
  {
    title: "Bulk pickup / Dropoff Flow",
    tooltip:
      "Use a cluster flow in cases where a driver is picking up multiple orders from a single location",
    steps: bulkPickupDropoffFlow,
  },
];

const DeliveryModel = ({ title, tooltip, steps }: DeliveryModel) => {
  const stepsLastIndex = steps.length - 1;
  const deliveryModelRef = useRef(null);
  return (
    <div className="delivery-model-item">
      <div
        ref={deliveryModelRef}
        className="delivery-model-item-title-container"
      >
        <h2>{title}</h2>
        <QuestionMark
          placement="right"
          overlayClassName="tool-tip-custom"
          getPopupContainer={() => deliveryModelRef.current}
        >
          {tooltip}
        </QuestionMark>
      </div>
      <div className="delivery-model-steps">
        {steps.map((step, index) => {
          return (
            <React.Fragment key={step.title + step.description}>
              <Tooltip
                placement="top"
                title={step.tooltip}
                overlayClassName={`tool-tip-custom ${
                  step.additionalBackground ? "additional-background" : ""
                }`}
                getPopupContainer={(triggerNode) => triggerNode}
              >
                <div
                  className={`delivery-model-step ${
                    step.additionalBackground ? "additional-background" : ""
                  }`}
                >
                  <h3>
                    {step.title}
                    <br></br>
                    <span>{step.specification}</span>
                  </h3>
                  <p>{step.description}</p>
                </div>
              </Tooltip>

              {index !== stepsLastIndex && <div className="arrow-right"></div>}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export const DeliveryModels = () => (
  <div className="delivery-model">
    {deliveryModels.map((model) => (
      <DeliveryModel
        key={model.title + model.steps.length}
        title={model.title}
        tooltip={model.tooltip}
        steps={model.steps}
      />
    ))}
  </div>
);

export const WelcomePage = ({ setFirstLogin }: WelcomePageProps) => {
  return (
    <div className="welcome-page">
      <div className="welcome-page-container">
        <div className="welcome-page-logo" />
        <div className="welcome-page-title">
          <h1>Welcome to the Bringg Integrations Portal</h1>
          <p>
            This portal is for a delivery provider’s IT Integrator to become
            familiar with the APIs andwebhooks you need to launch a successful
            integration with Bringg. Below are sample order flow APIs and
            webhooks to get you started.
            <br />* Denotes mandatory webhooks and APIs.
          </p>
        </div>

        <DeliveryModels />

        <div className="welcome-page-footer">
          <Button
            type="primary"
            size="large"
            onClick={() => setFirstLogin(false)}
          >
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
};
