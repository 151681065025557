export const API_HOSTS = {
  US1: "https://admin-api.bringg.com",
  US2: "https://us2-admin-api.bringg.com",
  EU1: "https://eu1-admin-api.bringg.com",
  EU2: "https://eu2-admin-api.bringg.com",
};

/* eslint-disable @typescript-eslint/camelcase */
export const DOCUMENTATION_LINKS = {
  api: {
    GET_TOKEN_URL: "https://developers.bringg.com/docs/get-token",
    assign_task:
      "https://developers.bringg.com/docs/driver-is-assigned-to-order",
    update_task: "https://developers.bringg.com/docs/update-order",
    cluster_checkin:
      "https://developers.bringg.com/docs/cluster-checkincheckout",
    cluster_checkout:
      "https://developers.bringg.com/docs/cluster-checkincheckout",
    start_task: "https://developers.bringg.com/docs/start-order-1",
    end_task: "https://developers.bringg.com/docs/complete-order",
    update_driver_location:
      "https://developers.bringg.com/docs/update-driver-location",
    checkin: "https://developers.bringg.com/docs/check-in",
    checkout: "https://developers.bringg.com/docs/check-out",
    create_note: "https://developers.bringg.com/docs/note-added",
    cancel_delivery: "https://developers.bringg.com/docs/cancel-order",
  },
  webhooks: {
    cancel_delivery_url:
      "https://developers.bringg.com/docs/order-is-cancelled-webhook",
    create_delivery_url:
      "https://developers.bringg.com/docs/delivery-created-webhook",
    get_quote_url: "https://developers.bringg.com/docs/get-quote-webhook",
    merchant_registered_url:
      "https://developers.bringg.com/docs/merchant-registered-webhook",
    update_delivery_url:
      "https://developers.bringg.com/docs/order-is-updated-webhook",
    create_return_delivery_url:
      "https://developers.bringg.com/docs/return-delivery-created",
  },
};
/* eslint-enable @typescript-eslint/camelcase */

export const LEARN_MORE_LINKS = {
  "/registration/webhooks/":
    "https://help.bringg.com/r/Integrations-Guide/Implement-your-Integration-with-Bringg-as-a-Delivery-Provider",
  "/registration/api/":
    "https://help.bringg.com/r/Integrations-Guide/Implement-your-Integration-with-Bringg-as-a-Delivery-Provider",
  "/dispatch/":
    "https://help.bringg.com/r/Integrations-Guide/Test-your-Integrations-with-Bringg-as-a-Delivery-Provider",
  "/settings/delivery-model/":
    "https://help.bringg.com/r/Integrations-Guide/Test-your-Integrations-with-Bringg-as-a-Delivery-Provider",
  "/settings/sandbox-api-key/":
    "https://help.bringg.com/r/Integrations-Guide/Test-your-Integrations-with-Bringg-as-a-Delivery-Provider",
};
