// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { RouteDetails } from "@bringg/react-pages/dist/components/pages/pages";

import { PrevMainPage } from "../../../features/main-page/main-page";
import { AppRoute } from "../route/route";

export const LoggedInRouter: FunctionComponent = () => {
  return (
    <HashRouter basename="/">
      <Switch>
        {appRoutes.map((route) => (
          <AppRoute {...route} key={route.path} />
        ))}
        <Route
          render={(props) => (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          )}
        />
      </Switch>
    </HashRouter>
  );
};

export const DEFAULT_ROUTE: RouteDetails = {
  path: "/",
  title: "Home",
  component: PrevMainPage,
};

const appRoutes: RouteDetails[] = [DEFAULT_ROUTE];
