// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react";

import { DeliveryModels } from "../../component/welcome-page/welcome-page";
import { NiceContainer } from "../nice-container/nice-container";
import { SandboxApiKey } from "./sandbox-api-key/sandbox-api-key";

const Settings = () => {
  return (
    <NiceContainer
      title="Settings"
      path="settings"
      items={[
        {
          key: "delivery-model",
          tab: "Delivery Model",
          component: <DeliveryModels />,
        },
        {
          key: "sandbox-api-key",
          tab: "Sandbox API Key",
          component: <SandboxApiKey />,
        },
      ]}
    />
  );
};

export { Settings };
