import { MapViewStore } from "./map-view/map-view-store";

export class ViewStore {
  mapViewStore: MapViewStore;

  constructor() {
    this.mapViewStore = new MapViewStore();
  }

  public async afterLogin(): Promise<void> {
    this.mapViewStore.afterLogin();
  }
}
