import {
  Task as BringgTask,
  TaskInventory,
  TaskNote,
  WayPoint,
} from "@bringg/types";
import { action, computed, observable } from "mobx";
import { getEnv } from "mobx-easy";

import { RootEnv } from "../../create-store";

export class Task {
  @observable _userName: string;
  // eslint-disable-next-line @typescript-eslint/camelcase
  way_points: WayPoint[];
  // eslint-disable-next-line @typescript-eslint/camelcase
  task_inventories: TaskInventory[];
  // eslint-disable-next-line @typescript-eslint/camelcase
  task_notes: TaskNote[];
  id: number;
  title: string;
  // eslint-disable-next-line @typescript-eslint/camelcase
  external_id: string;
  // eslint-disable-next-line @typescript-eslint/camelcase
  fleet_id: number;

  constructor(task: BringgTask, userName: string = null) {
    Object.assign(this, task);
    this.setUserName(userName);
  }

  @action
  public async setFleetId(fleetId: number): Promise<void> {
    const { dashboardSDK } = getEnv<RootEnv>();

    // eslint-disable-next-line @typescript-eslint/camelcase
    await dashboardSDK.sdk.tasks.update(this.id, { fleet_id: fleetId });
  }

  @computed
  public get userName(): string {
    return this._userName;
  }

  @action
  private setUserName(userName: string): void {
    this._userName = userName;
  }
}
