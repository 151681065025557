// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react";

import { LearnMoreButton } from "../../component/learn-more-button/learn-more-button";
import { SettingHeader } from "../../component/setting-header/setting-header";
import { useHasFeatureFlag } from "../../hooks/use-has-feature-flag";
import { useStore } from "../../stores/use-store";
import { CreateTaskButton } from "./create-task/create-task";
import { DispatchMap } from "./dispatch-map/dispatch-map";
import { DispatchTable } from "./dispatch-table/dispatch-table";
import { GetQuoteButton } from "./get-quote/get-quote";

export const Dispatch: FunctionComponent = () => {
  const {
    dataStore: { tasksStore, driversStore, fleetSettingsStore },
  } = useStore();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const newVersion = useHasFeatureFlag("enable_partner_portal");

  useEffect(() => {
    const init = async () => {
      await driversStore.fetch();
      await tasksStore.fetch();
      await fleetSettingsStore.fetch();
      setLoading(false);
    };

    init();
  }, [driversStore, tasksStore, fleetSettingsStore]);

  return useObserver(() => (
    <div className="dispatch">
      <SettingHeader
        title={
          <>
            {t("DISPATCH_TITLE")}
            <CreateTaskButton />
            {newVersion ? <GetQuoteButton /> : null}
          </>
        }
        description={
          newVersion ? (
            <>
              This page offers you a visualization of the order creation process
              using a sandbox shipper account that triggers notifications to and
              from your system when you request quotes, or create, update, and
              cancel orders.
              <LearnMoreButton />
            </>
          ) : null
        }
      />
      <DispatchTable loading={loading} />
      <DispatchMap loading={loading} />
    </div>
  ));
};
