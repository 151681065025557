import { Translations as WayPointCardTranslations } from "@bringg/react-components/dist/features/waypoint-card/wapoint-card";
import { TFunction } from "i18next";

export const getWayPointTranslations = (
  t: TFunction
): WayPointCardTranslations => {
  return {
    contact: t("CONTACT"),
    addedBy: t("ADDED_BY"),
    notes: t("NOTES"),
    inventories: t("INVENTORIES"),
    attachments: t("ATTACHMENTS"),
    scheduledFor: t("SCHEDULED_FOR"),
    timeWindow: t("TIME_WINDOW"),
    eta: t("ETA"),
    etl: t("ETL"),
    arrived: t("ARRIVED"),
    left: t("LEFT"),
    collectText: t("COLLECT"),
    deliverText: t("DELIVER"),
    collectedText: t("COLLECTED"),
    deliveredText: t("DELIVERED"),
    rejectedText: t("REJECTED"),
    editStop: t("EDIT_STOP"),
    of: t("OF"),
    stop: t("STOP"),
    photo: t("PHOTO"),
    signature: t("SIGNATURE"),
    additionalServices: t("ADDITIONAL_SERVICES"),
    destination: t("DESTINATION"),
    addressLineOne: t("ADDRESS_LINE_1"),
    addressLineTwo: t("ADDRESS_LINE_2"),
    scheduleTo: t("SCHEDULED_TO"),
    date: t("DATE"),
    time: t("TIME"),
    startDate: t("START_DATE"),
    startTime: t("START_TIME"),
    endDate: t("END_DATE"),
    endTime: t("END_TIME"),
    name: t("NAME"),
    phone: t("PHONE"),
    confirmDelete: t("CONFIRM_DELETE"),
    yes: t("YES"),
    no: t("NO"),
    asap: t("ASAP"),
    editItem: t("EDIT"),
    quantity: t("INVENTORY_QUANTITY"),
    trashItem: t("TRASH"),
    width: t("WIDTH"),
    height: t("HEIGHT"),
    inventoryLength: t("LENGTH"),
    save: t("SAVE"),
    cancel: t("CANCEL"),
    trackingLinks: t("TRACKING_LINKS"),
    addNote: t("ADD_NOTE"),
    addYourNote: t("ADD_YOUR_NOTE"),
    sharedPhone: t("SHARED_PHONE"),
    sharedEmail: t("SHARED_EMAIL"),
    sharedApp: t("SHARED_APP"),
    firstSeen: t("FIRST_SCREEN"),
    viewed: t("VIEWED"),
    times: t("TIMES"),
    link: t("LINK"),
  };
};
