import { TaskStatus } from "@bringg/types";
import _isNumber from "lodash/isNumber";
import { action, computed, observable } from "mobx";
import { addRoot, getEnv } from "mobx-easy";

import { RootEnv } from "../../create-store";
import { RootStore } from "../../root-store";
import { Task } from "./task";

const TaskDoneStatuses = [TaskStatus.Done, TaskStatus.Cancelled];

export interface TasksStore {
  getRoot: () => RootStore;
}

@addRoot
export class TasksStore {
  @observable.shallow tasksById: Map<number, Task> = new Map();

  @action
  public async setTasks(tasks: Bringg.Task[]) {
    await Promise.all(tasks.map(async (task) => await this.setTask(task)));
  }

  @computed
  public get tasks(): Task[] {
    return Array.from(this.tasksById.values());
  }

  @action
  public async get(taskId: number): Promise<Task> {
    if (this.tasksById.has(taskId)) {
      return this.tasksById.get(taskId);
    }

    const { dashboardSDK } = getEnv<RootEnv>();
    const task = await dashboardSDK.sdk.tasks.get(taskId);

    await this.setTask(task);

    return this.tasksById.get(taskId);
  }

  @action
  public async update(taskId: number, diff: Partial<Bringg.Task>) {
    const { dashboardSDK } = getEnv<RootEnv>();

    await dashboardSDK.sdk.tasks.update(taskId, diff);
  }

  @action
  public async cancel(taskId: number) {
    const { dashboardSDK } = getEnv<RootEnv>();

    await dashboardSDK.sdk.tasks.cancel(taskId);
  }

  @action
  public async createTaskForSandbox(task: Bringg.Task): Promise<void> {
    const { dashboardSDK } = getEnv<RootEnv>();

    await dashboardSDK.sdk.openFleets.createSandboxTask(task);
  }

  @action
  public async assignFleetToTask(taskId: number, fleetId: number) {
    await this.tasksById.get(taskId).setFleetId(fleetId);
  }

  public async fetch() {
    const { dashboardSDK } = getEnv<RootEnv>();
    const tasks: Bringg.Task[] = await dashboardSDK.sdk.tasks.getOpenTasks({
      limit: 1000,
    });

    await this.setTasks(tasks);
  }

  setTask = async (task: Bringg.Task) => {
    if (_isNumber(task.user_id)) {
      const {
        dataStore: { driversStore },
      } = this.getRoot();
      const user = await driversStore.getById(task.user_id);

      this.tasksById.set(task.id, new Task(task, user.name));
    } else {
      this.tasksById.set(task.id, new Task(task));
    }

    const {
      viewStore: { mapViewStore },
    } = this.getRoot();

    mapViewStore.setTaskMarker(task);
  };

  onTaskDeleted = (task: Bringg.Task) => {
    this.tasksById.delete(task.id);

    const {
      viewStore: { mapViewStore },
    } = this.getRoot();

    mapViewStore.deleteTaskMarker(task);
  };

  onTaskCreated = async (task: Bringg.Task) => {
    await this.setTask(task);

    /**
     * Automatically assign created task
     */
    const {
      dataStore: { fleetSettingsStore },
      uiStore: { authStore },
    } = this.getRoot();

    const newVersion = Boolean(
      authStore.user?.feature_flags?.enable_partner_portal
    );

    if (newVersion) {
      await this.update(task.id, { fleet_id: fleetSettingsStore.fleetId });
    }
  };

  onTaskUpdated = async (task: Bringg.Task) => {
    if (this.isTaskDone(task)) {
      return this.onTaskDeleted(task);
    }

    await this.setTask(task);
  };

  isTaskDone = (task: Bringg.Task): boolean => {
    return TaskDoneStatuses.includes(task.status);
  };

  afterLogin = () => {
    const { dashboardSDK } = getEnv<RootEnv>();

    dashboardSDK.sdk.tasks.onCreate(this.onTaskCreated);
    dashboardSDK.sdk.tasks.onUpdate(this.onTaskUpdated);
    dashboardSDK.sdk.tasks.onDelete(this.onTaskDeleted);
    dashboardSDK.sdk.tasks.attachSubscriptions();
  };

  afterLogout = () => {
    const { dashboardSDK } = getEnv<RootEnv>();

    dashboardSDK.sdk.tasks.unsubscribe();
    this.tasksById = new Map();
  };
}
