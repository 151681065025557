import { action, computed } from "mobx";
import { getEnv } from "mobx-easy";

import { RootEnv } from "../../create-store";

export enum FleetEndpointType {
  GetQuote = "get_quote_url",
  CreateOrder = "create_delivery_url",
  CancelOrder = "cancel_delivery_url",
  UpdateOrder = "update_delivery_url",
  MerchantRegistered = "merchant_registered_url",
  CreateReturnDelivery = "create_return_delivery_url",
}

export type FleetEndpointTypeToUrl = {
  [key in FleetEndpointType]: string;
};

export enum WebhookOption {
  Optional,
  Mandatory,
}

export const WebhookTypeToOptions: {
  [key in FleetEndpointType]: WebhookOption;
} = Object.freeze({
  [FleetEndpointType.GetQuote]: WebhookOption.Optional,
  [FleetEndpointType.UpdateOrder]: WebhookOption.Mandatory,
  [FleetEndpointType.CreateOrder]: WebhookOption.Mandatory,
  [FleetEndpointType.CancelOrder]: WebhookOption.Mandatory,
  [FleetEndpointType.MerchantRegistered]: WebhookOption.Mandatory,
  [FleetEndpointType.CreateReturnDelivery]: WebhookOption.Optional,
});

export class FleetSettings {
  endpoints: Partial<FleetEndpointTypeToUrl> = null;
  // eslint-disable-next-line @typescript-eslint/camelcase
  sandbox_endpoints: Partial<FleetEndpointTypeToUrl> = null;
  id: number = -1;
  uuid: string;
  name: string = "";
  key: string = "";
  approved: boolean = false;
  fullPayloadOnUpdate: boolean = false;

  constructor(settings: Partial<FleetSettings>) {
    Object.assign(this, settings);
  }

  @action
  public setEndpoints(endpoints: Partial<FleetEndpointTypeToUrl>) {
    this.endpoints = endpoints;
  }

  @action
  public setSandboxEndpoints(endpoints: Partial<FleetEndpointTypeToUrl>) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.sandbox_endpoints = endpoints;
  }

  @action
  public setApproved(approved: boolean) {
    this.approved = approved;
  }

  @action
  public setFullPayloadOnUpdate(fullPayloadOnUpdate: boolean) {
    this.fullPayloadOnUpdate = fullPayloadOnUpdate;
  }

  @computed
  public get isApproved(): boolean {
    return this.approved;
  }

  @computed
  public get getEndpoints(): Partial<FleetEndpointTypeToUrl> {
    return this.endpoints;
  }

  @computed
  public get getKey(): string {
    return this.key;
  }

  @computed
  public get getName(): string {
    return this.name;
  }

  @computed
  public get getId(): number {
    return this.id;
  }

  @computed
  public get getUuid(): string {
    return this.uuid;
  }

  @computed
  public get isFullPayloadOnUpdate(): boolean {
    return this.fullPayloadOnUpdate;
  }

  @action
  public async update() {
    const { dashboardSDK } = getEnv<RootEnv>();

    await dashboardSDK.sdk.fleetTemplates.update({
      endpoints: this.endpoints,
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      // eslint-disable-next-line
      sandbox_endpoints: this.sandbox_endpoints,
      name: this.name,
      id: this.id,
      // eslint-disable-next-line @typescript-eslint/camelcase
      configuration: { full_payload_on_update: this.fullPayloadOnUpdate },
    });
  }
}
