import { AdminCredentialsStore } from "./admin-credentials/admin-credentials-store";
import { DriversStore } from "./drivers/drivers-store";
import { FleetSettingsStore } from "./fleet-settings/fleet-settings-store";
import { RegisteredMerchantsStore } from "./registered-merchants/registered-merchants-store";
import { TasksStore } from "./tasks/tasks-store";

export class DataStore {
  fleetSettingsStore: FleetSettingsStore;
  tasksStore: TasksStore;
  driversStore: DriversStore;
  registeredMerchantsStore: RegisteredMerchantsStore;
  adminCredentialsStore: AdminCredentialsStore;

  constructor() {
    this.fleetSettingsStore = new FleetSettingsStore();
    this.tasksStore = new TasksStore();
    this.driversStore = new DriversStore();
    this.registeredMerchantsStore = new RegisteredMerchantsStore();
    this.adminCredentialsStore = new AdminCredentialsStore();
  }

  public afterLogin() {
    this.tasksStore.afterLogin();
    this.driversStore.afterLogin();
  }

  public afterLogout() {
    this.tasksStore.afterLogout();
    this.driversStore.afterLogout();
    this.registeredMerchantsStore.afterLogout();
    this.adminCredentialsStore.afterLogout();
    this.fleetSettingsStore.afterLogout();
  }
}
