// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@bringg/react-components/dist/components/spinner/spinner";
import { useObserver } from "mobx-react";

import { CopyButton } from "../../../../component/copy-button/copy-button";
import { useStore } from "../../../../stores/use-store";

interface Props {
  loading: boolean;
}

export const AdminEndpoints: FunctionComponent<Props> = ({ loading }) => {
  const { t } = useTranslation();
  const {
    dataStore: { adminCredentialsStore },
  } = useStore();

  return useObserver(() => (
    <div className="admin-endpoints">
      {loading ? (
        <Spinner className="spinner" />
      ) : (
        <>
          <div>
            <span className="text-field">
              {adminCredentialsStore.getEndpoint}
            </span>
            <CopyButton
              textToCopy={adminCredentialsStore.getEndpoint}
              buttonText={t("COPY_ADMIN_ENDPOINT_TEXT")}
            />
          </div>
          <div className="description">{t("ADMIN_ENDPOINT_DESCRIPTION")}</div>
        </>
      )}
    </div>
  ));
};
