import { StorageItems } from "@bringg/frontend-utils/dist/services/credentials-storage";
import { FleetTemplate, GetQuoteRequest, OpenFleet } from "@bringg/types";
import axios, { AxiosResponse } from "axios";
import _first from "lodash/first";
import _get from "lodash/get";
import _pick from "lodash/pick";
import { action, computed, IObservableArray, observable } from "mobx";
import { getEnv, getRoot } from "mobx-easy";

import { RootEnv } from "../../create-store";
import { RootStore } from "../../root-store";
import { FleetEndpointTypeToUrl, FleetSettings } from "./fleet-settings";
import { ServiceEndpoint } from "./service-endpoint";

const ENDPOINTS_INITIAL_VALUES: FleetEndpointTypeToUrl = Object.freeze({
  // eslint-disable-next-line @typescript-eslint/camelcase
  create_delivery_url: "",
  // eslint-disable-next-line @typescript-eslint/camelcase
  cancel_delivery_url: "",
  // eslint-disable-next-line @typescript-eslint/camelcase
  update_delivery_url: "",
  // eslint-disable-next-line @typescript-eslint/camelcase
  get_quote_url: "",
  // eslint-disable-next-line @typescript-eslint/camelcase
  merchant_registered_url: "",
  // eslint-disable-next-line @typescript-eslint/camelcase
  create_return_delivery_url: "",
});

export interface FleetApiService {
  type: string;
  url: string;
}

const GET_TOKEN_TYPE = "GET_TOKEN_URL";
const GET_TOKEN_URL_SUFFIX = "/oauth/token";

export class FleetSettingsStore {
  @observable.shallow fleetSettings: FleetSettings = null;
  @observable.shallow
  serviceEndpoints: IObservableArray<FleetApiService> = [] as IObservableArray;
  @observable sandboxFleetId: number = null;
  @observable isFetched: boolean = false;

  @action
  public setEndpoints(endpoints: Partial<FleetEndpointTypeToUrl>) {
    this.fleetSettings.setEndpoints(endpoints);
  }

  @action
  public setSandboxEndpoints(endpoints: Partial<FleetEndpointTypeToUrl>) {
    this.fleetSettings.setSandboxEndpoints(endpoints);
  }

  @action
  public setFullPayloadOnUpdate(fullPayloadOnUpdate: boolean) {
    this.fleetSettings.setFullPayloadOnUpdate(fullPayloadOnUpdate);
  }

  @action
  public async updateFleetSetting() {
    await this.fleetSettings.update();
  }

  @computed
  public get endpoints(): Partial<FleetEndpointTypeToUrl> {
    return this.fleetSettings.getEndpoints;
  }

  @computed
  public get key(): string {
    return this.fleetSettings.getKey;
  }

  @computed
  public get id(): number {
    return this.fleetSettings && this.fleetSettings.getId;
  }

  @computed
  public get isApproved(): boolean {
    return this.fleetSettings && this.fleetSettings.isApproved;
  }

  @computed
  public get name(): string {
    return this.fleetSettings && this.fleetSettings.getName;
  }

  @computed
  public get settings(): FleetSettings {
    return this.fleetSettings;
  }

  @computed
  public get fleetId(): number {
    return this.sandboxFleetId;
  }

  @computed
  public get uuid(): string {
    return this.fleetSettings.getUuid;
  }

  public async fetch() {
    const { dashboardSDK } = getEnv<RootEnv>();
    const openFleet: OpenFleet = await dashboardSDK.sdk.openFleets.get();
    const fleetTemplate = _first(openFleet.fleet_templates);

    this.fleetSettings = new FleetSettings({
      ..._pick(fleetTemplate, "id", "name", "uuid", "approved"),
      key: _get(fleetTemplate, "sandbox_fleet.data.api_key"),
      endpoints: { ...ENDPOINTS_INITIAL_VALUES, ...fleetTemplate.endpoints },
      // eslint-disable-next-line @typescript-eslint/camelcase
      sandbox_endpoints: {
        ...ENDPOINTS_INITIAL_VALUES,
        ...(fleetTemplate?.sandbox_endpoints ?? {}),
      },
      fullPayloadOnUpdate: _get(
        fleetTemplate,
        "configuration.full_payload_on_update"
      ),
    });

    if (!this.isFetched) {
      await this.setStaticData(openFleet, fleetTemplate);
      this.isFetched = true;
    }
  }

  @action
  public async getQuotes(data: GetQuoteRequest): Promise<AxiosResponse> {
    const { authentication } = getEnv<RootEnv>();
    const {
      uiStore: { authStore },
    } = getRoot<RootStore>();

    return axios.post(
      `${authStore.apiEndpoint}/quotes-service/get_quotes`,
      data,
      {
        headers: {
          Authorization: `Bearer ${authentication.getCredentials(
            StorageItems.TOKEN
          )}`,
        },
      }
    );
  }

  private async setStaticData(
    openFleet: OpenFleet,
    fleetTemplate: FleetTemplate
  ) {
    const {
      uiStore: { authStore },
    } = getRoot<RootStore>();
    const getTokenEndpoint = new ServiceEndpoint({
      type: GET_TOKEN_TYPE,
      url: `${authStore.apiEndpoint}${GET_TOKEN_URL_SUFFIX}`,
    });

    this.serviceEndpoints.push(getTokenEndpoint);

    const endpoints: FleetApiService[] = _get(
      openFleet,
      "service_endpoints",
      []
    );

    endpoints.forEach((endpoint) =>
      this.serviceEndpoints.push(new ServiceEndpoint(endpoint))
    );

    this.sandboxFleetId = _get(fleetTemplate, "sandbox_fleet.id");
  }

  @action
  public afterLogout() {
    this.serviceEndpoints.clear();
    this.isFetched = false;
  }
}
