// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Row from "@bringg/react-components/dist/components/row/row";
import TableWrapper from "@bringg/react-components/dist/components/table/table";
import { TFunction } from "i18next";
import { useObserver } from "mobx-react";

import { CertificationPopup } from "../../../component/certification-popup/certification-popup";
import { CopyButton } from "../../../component/copy-button/copy-button";
import { SettingHeader } from "../../../component/setting-header/setting-header";
import { useHasFeatureFlag } from "../../../hooks/use-has-feature-flag";
import { FleetApiService } from "../../../stores/data/fleet-settings/fleet-settings-store";
import { useStore } from "../../../stores/use-store";
import { EndpointsList } from "../endpoints-list/endpoints-list";

export const getColumns = (t: TFunction) => {
  return [
    {
      title: t("SERVICES_APIS_TYPE_COLUMN"),
      dataIndex: "type",
      render: (text: string, record: FleetApiService) => (
        <div className="api_type_column">{t(record.type)}</div>
      ),
    },
    {
      title: t("SERVICES_APIS_URL_COLUMN"),
      dataIndex: "url",
    },
    {
      render: (text: string, record: FleetApiService) => (
        <CopyButton buttonText={t("COPY_API_TEXT")} textToCopy={record.url} />
      ),
    },
  ];
};

export const ApisList: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const {
    dataStore: { fleetSettingsStore },
  } = useStore();
  const newVersion = useHasFeatureFlag("enable_partner_portal");
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      await fleetSettingsStore.fetch();
      setLoading(false);
    };

    init();
  }, [fleetSettingsStore]);

  return useObserver(() =>
    newVersion ? (
      <EndpointsList type="api" />
    ) : (
      <Row className="api-settings" type="flex" justify="space-around">
        {!fleetSettingsStore.isApproved && (
          <CertificationPopup
            name={fleetSettingsStore.name}
            id={fleetSettingsStore.id}
          />
        )}
        <div className="setting-body">
          <SettingHeader
            title={t("API_SETTING_TITLE")}
            description={t("API_SETTING_DESCRIPTION")}
          />
          <div className="sub-title">{t("APIS_LIST_TITLE")}</div>
          <TableWrapper
            loading={isLoading}
            collection={fleetSettingsStore.serviceEndpoints}
            columns={getColumns(t)}
            rowKey="type"
          />
        </div>
      </Row>
    )
  );
};
