import { AuthStore } from "./auth-store/auth-store";

export class UiStore {
  authStore: AuthStore;

  constructor() {
    this.authStore = new AuthStore();
    // For testing
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.__changeFirstLogin = this.authStore.changeFirstLogin;
  }
}
