// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Menu,
  notification,
  Row,
  Switch as Switcher,
} from "antd";
import { get } from "lodash";

import UrlCopier from "../../../component/url-copier/url-copier";
import { API_HOSTS, DOCUMENTATION_LINKS } from "../../../consts";
import bringgToDP from "../../../images/bringg-to-dp.svg";
import dpToBringg from "../../../images/dp-to-bringg.svg";
import link from "../../../images/link.svg";
import save from "../../../images/save.svg";
import {
  FleetEndpointType,
  FleetSettings,
} from "../../../stores/data/fleet-settings/fleet-settings";
import { useStore } from "../../../stores/use-store";

import "./endpoints-list.scss";

interface EndpointsListProps {
  type: "webhooks" | "api";
  onSave?: Function;
  settings?: FleetSettings;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const EndpointsList = ({ settings, type, onSave }: EndpointsListProps) => {
  const {
    dataStore: { fleetSettingsStore },
  } = useStore();
  const { t } = useTranslation();
  const history = useHistory();
  const routeMatch = useRouteMatch(`/registration/${type}/:endpoint`);

  const [form] = Form.useForm();

  useEffect(() => {
    if (settings?.endpoints) {
      form.setFieldsValue({
        fullPayloadOnUpdate: settings.fullPayloadOnUpdate,
        endpoints: {
          ...settings.endpoints,
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        sandbox_endpoints: {
          ...(settings?.sandbox_endpoints ?? {}),
        },
      });
    }
  }, [form, settings]);

  const onFinish = () => {
    onSave({
      ...settings,
      fullPayloadOnUpdate: form.getFieldsValue().fullPayloadOnUpdate,
      endpoints: {
        ...settings.endpoints,
        ...form.getFieldsValue().endpoints,
      },
      // eslint-disable-next-line @typescript-eslint/camelcase
      sandbox_endpoints: {
        ...settings.sandbox_endpoints,
        ...form.getFieldsValue()?.sandbox_endpoints,
      },
    });

    notification.success({
      message: "Saved",
      placement: "bottomRight",
    });
  };

  const collection = useMemo(
    () =>
      type === "api"
        ? (fleetSettingsStore.serviceEndpoints as any[])
        : (Object.values(FleetEndpointType).map((type) => ({ type })) as any[]),
    [fleetSettingsStore.serviceEndpoints, type]
  );

  if (!collection.length) {
    return null;
  }

  const getUrls = (url: string) => {
    const { pathname } = new URL(
      url.substr(0, 2) === "//" ? `https:${url}` : url
    );

    return Object.entries(API_HOSTS).map(([name, host]) => ({
      title: name,
      url: `${host}${pathname}`,
    }));
  };

  return (
    <div className="endpoints-list">
      <Layout>
        <Layout.Sider width={190}>
          <Menu
            selectedKeys={[get(routeMatch, "params.endpoint")]}
            onSelect={(event) =>
              history.push(`/registration/${type}/${event.key}`)
            }
          >
            {collection.map((item) => (
              <Menu.Item key={item.type}>
                <h3>{t(item.type)}</h3>

                <div className="endpoints-list-direction">
                  <span>
                    {type === "webhooks" ? "Webhooks" : "Api Requests"}
                  </span>
                  <img
                    alt="direction logo"
                    src={type === "webhooks" ? bringgToDP : dpToBringg}
                  />
                </div>
              </Menu.Item>
            ))}
          </Menu>
        </Layout.Sider>
        <Layout.Content className="endpoints-list-content" id="test">
          <Switch>
            <Route
              path={`/registration/${type}/:endpoint`}
              render={(props) => {
                const { endpoint } = props.match.params;
                const serviceEndpoint = collection.find(
                  (item) => item.type === endpoint
                );

                return (
                  <Form form={form} onFinish={onFinish}>
                    <Row>
                      <Col flex="auto">
                        <h1>{t(serviceEndpoint.type)}</h1>
                      </Col>
                      <Col flex="none">
                        <Button
                          className="endpoints-list-action-button"
                          type="link"
                          href={DOCUMENTATION_LINKS[type][endpoint]}
                          icon={<img alt="documentation icon" src={link} />}
                          target="_blank"
                        >
                          Technical Guideline
                        </Button>

                        {type === "webhooks" && (
                          <Button
                            className="endpoints-list-action-button"
                            type="link"
                            icon={<img alt="save icon" src={save} />}
                            onClick={() => form.submit()}
                          >
                            Save
                          </Button>
                        )}
                      </Col>
                    </Row>

                    <p>{t(`${serviceEndpoint.type}_description`)}</p>

                    {serviceEndpoint.type === FleetEndpointType.UpdateOrder && (
                      <Row
                        align="middle"
                        gutter={[10, 0]}
                        className="endpoints-list-checker"
                      >
                        <Col>
                          <Form.Item
                            name="fullPayloadOnUpdate"
                            valuePropName="checked"
                          >
                            <Switcher />
                          </Form.Item>
                        </Col>

                        <Col>
                          <span>Send full delivery updated payload</span>
                        </Col>
                      </Row>
                    )}

                    <Divider />

                    {type === "webhooks" && (
                      <>
                        <div className="endpoints-list-block">
                          <h3>Sandbox</h3>

                          <Form.Item
                            label="Sandbox URL"
                            name={["sandbox_endpoints", serviceEndpoint.type]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter webhooks url here"
                            />
                          </Form.Item>

                          <h4>Sandbox API Key</h4>
                          <span>
                            Find the sandbox API Key in{" "}
                            <Link to="/settings/sandbox-api-key">Settings</Link>
                          </span>
                        </div>

                        <div className="endpoints-list-block">
                          <h3>Production</h3>

                          <Form.Item
                            label="Production URL"
                            name={["endpoints", serviceEndpoint.type]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter webhooks url here"
                            />
                          </Form.Item>

                          <h4>Production API Key</h4>
                          <span>
                            Find the production API key in{" "}
                            <Link to="/registration/merchants">
                              Registered Merchants
                            </Link>
                          </span>
                        </div>
                      </>
                    )}

                    {type === "api" && (
                      <div className="endpoints-list-block">
                        <h3>Production & Sandbox API URL</h3>
                        <p>
                          See the{" "}
                          <Link to="/registration/merchants">
                            Registered Merchants
                          </Link>{" "}
                          information to find out which URL below applies to
                          your shipper. Copy that URL only.
                        </p>

                        {getUrls(serviceEndpoint.url).map(({ url, title }) => (
                          <UrlCopier key={url} title={title}>
                            {url}
                          </UrlCopier>
                        ))}

                        <Divider />

                        <h4>Production API Key</h4>
                        <span>
                          Find the production API key in{" "}
                          <Link to="/registration/merchants">
                            Registered Merchants
                          </Link>
                        </span>

                        <h4>Sandbox API Key</h4>
                        <span>
                          Find the sandbox API Key in{" "}
                          <Link to="/settings/sandbox-api-key">Settings</Link>
                        </span>
                      </div>
                    )}
                  </Form>
                );
              }}
            />

            <Redirect to={`/registration/${type}/${collection?.[0].type}`} />
          </Switch>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export { EndpointsList };
